import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useReducer,
} from "react";
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Header,
  Table,
  Button,
  Icon,
  Segment,
  Label,
  Popup,
  FormGroup,
  FormField,
  Checkbox,
} from "semantic-ui-react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { HttpTrigger } from "../../lib/useFetch.tsx";
import { SuccessToast, ErrorToast } from "../../utils/ProjectToast.js";

// import Skeleton from "react-loading-skeleton";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import axios from "axios";
// import { CustomWhatsappMessages } from "../../utils/whatsapp.js";
// import { SuccessToast, ErrorToast } from "../../utils/ProjectToast.js";
// import { ToastContainer } from "react-toastify";
// import Customspinner from "../Customspinner.tsx";
// import { HttpTrigger } from "../../lib/useFetch.tsx";
// import { Result, useGlobalContext } from "../../lib/types.ts";
// import { ValidateCustomer } from "../../lib/formvalidation.ts";
// import { EmployeDetails } from "../../lib/dbobjectmodel.ts";
// import { EmployeInitialState } from "../../lib/initialState.ts";

function UserRoleTable({ headers, allMenus = [], selectedDepartment }) {
  const [isChecked, setIsChecked] = useState(false);
  const [index, setIndex] = useState(null);
  const [menu, setMenu] = useState("");
  const [value, setValue] = useState(null);
  const [currentData, setCurrentData] = useState(allMenus);

  useEffect(() => {
    setCurrentData(allMenus);
  }, []);

  const handleChange = (index, value, access, data) => {
    setValue(value);
    setIndex(index);
    setMenu(access);
    setTimeout(() => {
      let buildData = {
        department: selectedDepartment,
        menuname: data?.menuname,
        all: data.all ? 1 : 0,
        view: data.view ? 1 : 0,
        add: data.add ? 1 : 0,
        edit: data.edit ? 1 : 0,
        delete: data.delete ? 1 : 0,
      };

      HttpTrigger(process.env.REACT_APP_UPDATE_CONFIG_MENUS, "PUT", buildData)
        .then((res) => {
          if (res.message === "success") {
            SuccessToast("Details saved successfully!");
          } else {
            ErrorToast(
              "Something went wrong, please try again after sometime!"
            );
          }
        })
        .catch((error) => {
          ErrorToast("Something went wrong, please try again after sometime!");
        });
    }, 100);
  };

  useEffect(() => {
    if (index != null) {
      if (menu != "all") {
        allMenus[index][menu] = value;
        const checkAll = Object.values(allMenus[index]).filter(
          (value) => !value
        ).length;

        if (checkAll === 1) {
          allMenus[index]["all"] = value;
          return;
        }
        if (!value) {
          allMenus[index]["all"] = false;
        }
      } else if (menu === "all" && value) {
        allMenus[index]["view"] = true;
        allMenus[index]["add"] = true;
        allMenus[index]["edit"] = true;
        allMenus[index]["delete"] = true;
        allMenus[index][menu] = value;
      } else {
        allMenus[index]["view"] = false;
        allMenus[index]["add"] = false;
        allMenus[index]["edit"] = false;
        allMenus[index]["delete"] = false;
        allMenus[index][menu] = value;
      }
      setTimeout(() => {
        setCurrentData((state) => [...allMenus]);
        setIndex(null);
      }, 100);
    }
  });

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Table celled size="small" color="teal">
                <TableHeader>
                  <TableRow>
                    {headers
                      ? headers.map((header) => {
                          return !header.ishide ? (
                            <TableHeaderCell
                              width={50}
                              textAlign="center"
                              verticalAlign="middle"
                            >
                              {" "}
                              {header.text}
                            </TableHeaderCell>
                          ) : (
                            ""
                          );
                        })
                      : ""}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {(currentData || []).map((data, idx) => {
                    return (
                      <TableRow key={data.id} verticalAlign="top">
                        {headers.map((h, id) => {
                          return id == 0 ? (
                            <TableCell
                              textAlign="center"
                              verticalAlign="middle"
                            >
                              {data[h.value]}
                            </TableCell>
                          ) : (
                            <TableCell
                              textAlign="center"
                              verticalAlign="middle"
                            >
                              <Checkbox
                                id={h + idx + id}
                                checked={data[h.value]}
                                onClick={() => {
                                  setTimeout(() => {
                                    handleChange(
                                      idx,
                                      !data[h.value],
                                      h.value,
                                      data
                                    );
                                  }, 100);
                                }}
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default UserRoleTable;
