import React, { useEffect, useState } from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
} from "semantic-ui-react";
import "react-toastify/dist/ReactToastify.css";
import ModelTable from "../components/modeltable.tsx";
import { ToastBody } from "reactstrap";
import { ToastContainer } from "react-toastify";
import Customspinner from "../components/Customspinner.tsx";
import {
  ErrorToast,
  maxLengthCheck,
  SuccessToast,
} from "../utils/ProjectToast.js";
import {
  ChitCardCreation,
  CustomerDetail,
  PaymentHistory,
} from "../lib/dbobjectmodel.ts";
import {
  ChitCardCreationInitialState,
  CustomerInitialState,
} from "../lib/initialState.ts";
import {
  HttpGet,
  HttpKnexGet,
  HttpLoginTrigger,
  HttpSKTMTrigger,
  HttpTrigger,
  useFetch,
} from "../lib/useFetch.tsx";
import Cardtable from "../components/Table/cardtable.tsx";
import CustomerDetails from "./customerdetail.tsx";
import CustomTable from "../components/Table/table.tsx";
import { TableHeaders } from "../components/Table/tableheader.js";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

function SchemeClose(props: any) {
  const [ChitCardDetails, setChitCardDetails] = useState<ChitCardCreation>(
    ChitCardCreationInitialState
  );
  const [isValid, setisValid] = useState(false);
  const [spinners, setspinners] = useState(false);
  const [Buttontext, setButtontext] = useState("save");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listedCustomer, setListedCustomer] = useState<CustomerDetail[]>();
  const [searchCustomer, setSearchCustomer] = useState<boolean>(false);
  const [CustomerDetails] = useState<CustomerDetail>(CustomerInitialState);
  const [paymentHistory, setPaymentHistory] = useState<PaymentHistory[]>();
  const [isPaymentHistoryOpen, setPaymenyHisportyOpen] =
    useState<boolean>(false);

  const handleChange = (e: any, actionMeta: any) => {
    if (e.target.value.length > 0 && e.target.value.length < 10) {
      setSearchCustomer(true);
    }

    setChitCardDetails({
      ...ChitCardDetails,
      [e.target.name]: e.target.value,
    });
  };

  const populateCustomer = () => {
    setIsModalOpen(!isModalOpen);
    HttpKnexGet(
      `${process.env.REACT_APP_GET_URL_CUSTOMER}`,
      ChitCardDetails?.customermobilenumber
    )
      .then((res: CustomerDetail) => {
        if (res[0].length === 0) {
          ErrorToast(
            "Please check mobile number!, this customer not registered with us. Please register and try again"
          );
          setSearchCustomer(false);
          return;
        } else {
          getCustomerSchemes(
            String(process.env.REACT_APP_GET_CUSTOMER_MATURTITY_SCHEME),
            Number(res[0][0].customerid)
          ).then((result) => {
            setTimeout(() => {
              res[0][0].availableschemes = result;
              setListedCustomer(res[0]);
            }, 500);
            setSearchCustomer(false);
          });
        }
      })
      .catch((err) => {
        setSearchCustomer(false);
        ErrorToast("Something went wrong, please try again after sometime!");
      });
  };

  const getCustomerSchemes = (url: any, id: number): Promise<any> => {
    let _res: any;
    return new Promise((resolve) => {
      HttpKnexGet(url, id).then((res: any) => {
        setTimeout(() => {
          if (res.length > 0) {
            _res = res;
          } else {
            _res = [];
          }
          resolve(_res);
        }, 500);
      });
    });
  };

  useEffect(() => {
    if (ChitCardDetails?.customermobilenumber.length === 10) {
      populateCustomer();
    }
    return () => {
      setListedCustomer([]);
    };
  }, [ChitCardDetails?.customermobilenumber]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (ChitCardDetails?.customermobilenumber?.length === 10) {
        handleEnterKeyPress();
      }
    }
  };

  const handleEnterKeyPress = () => {
    populateCustomer();
  };

  const getCustomerPaymentHistory = (
    url: any,
    cusandscheme: string
  ): Promise<any> => {
    let _res: any;
    return new Promise((resolve) => {
      HttpKnexGet(url, cusandscheme).then((res: any) => {
        setTimeout(() => {
          if (res.length > 0) {
            res.map((item: any) => {
              item.paiddate = item?.paiddate
                .split("T")[0]
                .split("-")
                .reverse()
                .join("-");
            });

            _res = res;
          } else {
            _res = [];
          }
          resolve(_res);
        }, 500);
      });
    });
  };

  const processData = (data: any) => {
    if (data.task === "paymenthistory") {
      getCustomerPaymentHistory(
        String(process.env.REACT_APP_GET_CUSTOMER_PAYMENT_HISTORY),
        `${data.customerid}~${data.schemeid}~${data.groupnumber}`
      ).then((result) => {
        setTimeout(() => {
          setPaymenyHisportyOpen(!isPaymentHistoryOpen);
          setPaymentHistory(result);
        }, 500);
      });
    } else {
      HttpLoginTrigger(
        String(process.env.REACT_APP_GET_CUSTOMER_CLOSE_SCHEME),
        "POST",
        data
      )
        .then((res: any) => {
          console.log(res.message.message);

          if (res.message.message === "success") {
            SuccessToast(
              "Redeem link send successfully, please ask customer to approve the redeem link!"
            );
            setTimeout(() => {
              setspinners(false);
              populateCustomer();
            }, 1000);
          } else {
            ErrorToast("something went wrong, try again after sometime");
            setspinners(false);
          }
        })
        .catch((error) => {});
    }
  };

  const toggle = () => {
    setPaymenyHisportyOpen(!isPaymentHistoryOpen);
  };

  return (
    <>
      {!spinners ? (
        <div className="content">
          <Container className="project-form">
            <Form>
              <FormGroup widths="equal">
                <FormInput
                  fluid={true}
                  type="number"
                  value={ChitCardDetails?.customermobilenumber}
                  label="Mobile Number"
                  error={
                    ChitCardDetails?.customermobilenumber == ""
                      ? props.isValid
                      : false
                  }
                  placeholder="Mobile Number"
                  id="customermobilenumber"
                  name="customermobilenumber"
                  onChange={handleChange}
                  onInput={maxLengthCheck}
                  maxLength="10"
                  loading={searchCustomer}
                  onKeyDown={handleKeyDown}
                />
              </FormGroup>

              {ChitCardDetails.customermobilenumber.length === 10 ? (
                <>
                  <Cardtable
                    isOpen={isModalOpen}
                    data={listedCustomer || []}
                    isPayment={true}
                    isedit={false}
                    isdelete={false}
                    setData={processData}
                  />
                </>
              ) : (
                ""
              )}
            </Form>
            <ToastContainer />
          </Container>
          <Modal isOpen={isPaymentHistoryOpen} backdrop={true} size="large">
            <ModalHeader>
              <div>
                <h5>Payment History</h5>
              </div>
              <div>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={toggle}
                ></button>
              </div>
            </ModalHeader>
            <ModalBody>
              <CustomTable
                datas={paymentHistory || []}
                headers={TableHeaders.paymentHistory}
                isedit={false}
                isdelete={false}
                iswhatapp={false}
                issms={false}
                iscustomer={false}
                isdefault={false}
                isapprove={false}
                isfilter={false}
              />
            </ModalBody>
          </Modal>
        </div>
      ) : (
        <Customspinner />
      )}
    </>
  );
}
export default SchemeClose;
