import React from "react";
import { Dimmer, Image, Loader } from "semantic-ui-react";
import loader from "../assets/img/SKTM LOGO_1.gif";
function Customspinner(props: any) {
  return (
    <>
      <Dimmer active inverted>
        <div className="loader">{props.text}</div>
        {/* <div>
          <img src={loader} width="50%" height="50%" />
        </div> */}
      </Dimmer>
    </>
  );
}
export default Customspinner;
