import {
  BranchDetail,
  ChitGiftDetail,
  ConcernRoleDetail,
  CustomerDetail,
  DashBoardCounts,
  EmployeDetails,
  EmployeeConfigMenus,
  PaymentDetail,
  type SchemeDetail,
  Payments,
  paymentMode,
  ChitCardCreation,
  CardOtp,
  CustomerSchemes,
} from "./dbobjectmodel";
import { type Concerns } from "./dbobjectmodel";
import { GroupDetail } from "../lib/dbobjectmodel";

const firstDay = new Date();

export const SchemeInitalState: SchemeDetail = {
  schemeid: 0,
  entrynumber: String(Math.floor(10000000 + Math.random() * 90000000)),
  entrydate: firstDay.toISOString().split("T")[0],
  schemename: "",
  gift: false,
  luckydraw: false,
  weight: "1",
  status: false,
  CreatedBy: 0,
  ModifiedBy: 0,
  isdeleted: false,
  deletedBy: 0,
  insert_update: 1,
  deletedDate: "",
  collectiontype: true,
  installmentamount: "",
  yellowgoldwastage: "",
  antiqgoldwastage: "",
  diamondmc: "",
  diamondcent: "",
  silvermc: "",
  giftarticlemrp: "",
  code: "",
};

export const ConcernInitialState: Concerns = {
  concernid: 0,
  companycode: "",
  companyname: "",
  companytype: "",
  ownerpartnername: "",
  yearcode: "",
  doornumber: "",
  street: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  country: "India",
  pincode: "",
  mobilenumber: "",
  landlinenumber: "",
  faxnumber: "",
  gstn: "",
  tan: "",
  cin: "",
  pan: "",
  msme: "",
  CreatedBy: 0,
  ModifiedBy: 0,
  Isdeleted: false,
  deletedBy: 0,
  deletedDate: null,
  insert_update: 1,
};

const today = new Date();
export const firstDays = new Date(today.getFullYear(), today.getMonth(), 2);
export const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);

export const GroupinitialState: GroupDetail = {
  serialnumber: 0,
  active: true,
  groupname: "",
  startdate: firstDays.toISOString().split("T")[0],
  enddate: lastDay.toISOString().split("T")[0],
  CreatedBy: 0,
  ModifiedBy: 0,
  Isdeleted: false,
  deletedBy: 0,
  insert_update: 1,
  deletedDate: null,
  installmentamount: 0,
  branchname: "",
};

export const CustomerInitialState: CustomerDetail = {
  customerName: "",
  cardnumber: "",
  aadharnumber: "",
  branch: "",
  gender: "",
  religion: "",
  occupation: "",
  birthdate: "",
  anniversarydate: "",
  doorNo: "",
  street: "",
  pinCode: "",
  mobileNo: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  email: "",
  employeeid: "",
  nomineeName: "",
  nomineeRelation: "",
  nomineeMobile: "",
  CreatedBy: 0,
  ModifiedBy: 0,
  Isdeleted: false,
  deletedBy: 0,
  insert_update: 1,
  deletedDate: null,
  schemeid: "",
  schemename: "",
  schemeamount: "",
  groupserialnumber: "",
  groupname: "",
  startdate: firstDays.toISOString().split("T")[0],
  enddate: lastDay.toISOString().split("T")[0],
  customerid: 0,
  relationship: "",
  photo1: "https://react.semantic-ui.com/images/wireframe/image.png",
  photo2: "https://react.semantic-ui.com/images/wireframe/image.png",
  joiningecno: "",
  availableschemes: [],
  paymenthistory: [],
};

export const ChitGiftInitialState: ChitGiftDetail = {
  cardnumber: "",
  groupnumber: "",
  cusname: "",
  barcode: "",
  groupname: "",
  giftvalue: "",
  installment: 0,
  gifttype: "",
  CreatedBy: 0,
  ModifiedBy: 0,
  isdeleted: false,
  deletedBy: 0,
  insert_update: 1,
  giftid: 0,
};

export const PaymentDetailsInitialState: PaymentDetail = {
  entryno: "",
  cardnumber: "",
  groupname: "",
  groupserialnumber: "",
  schemeamount: 0,
  installmentweight: "",
  actualinstallment: "",
  maturitydate: "",
  recievedinstallment: 0,
  pendinginstallment: "",
  customerName: "",
  doorNo: "",
  street: "",
  pinCode: "",
  mobileNo: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  customeremail: "",
  recievedby: "",
  creditcard: "",
  cash: "",
  oldchit: "",
  CreatedBy: 0,
  ModifiedBy: 0,
  Isdeleted: false,
  deletedBy: 0,
  insert_update: 1,
  deletedDate: null,
  cardtype: false,
  machinename: "",
  machinenumber: "",
  referencenumber: "",
  bankname: "",
  bankcashamount: "",
  cashamount: "",
  cardamount: "",
};

export const PaymentInitalState: Payments = {
  cardnumber: "",
  customerid: 0,
  paymentmodes: "",
  cardtype: false,
  machinename: "",
  machinenumber: "",
  referencenumber: "",
  bankname: "",
  bankamount: "",
  cashamount: "",
  cardamount: "",
  machinepayment: "",
  groupno: 0,
  schemeid: 0,
};

export const EmployeInitialState: EmployeDetails = {
  ecno: "",
  employename: "",
  department: "",
  division: "",
  designation: "",
  yearcode: "",
};

export const ConcernUserInitialState: ConcernRoleDetail = {
  concernroleid: 0,
  concern: "",
  userrole: "",
  insert_update: 1,
  CreatedBy: 0,
  ModifiedBy: 0,
  isdeleted: "Yes",
  deletedBy: 0,
  deletedDate: null,
  concernname: "",
};

export const BranchInitialState: BranchDetail = {
  branchname: "",
  branchserialno: "",
  companyname: "",
  legalname: "",
  branchcode: "",
  branchdoornumber: 0,
  street: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
  mobilenumber: 0,
  landlinenumber: 0,
  faxnumber: "",
  gstn: "",
  tan: "",
  cin: "",
  pan: "",
  msme: "",
};

export const DashboardInitialState: DashBoardCounts = {
  concencount: 0,
  branchcount: 0,
  schemecount: 0,
  groupcount: 0,
  customercount: 0,
  employecont: 0,
  giftcount: 0,
  expiredgroupcont: 0,
};

export const ChitGiftSchemeDetailInitialState = {
  cardnumber: 0,
  groupnumber: 0,
  cusname: "",
  groupname: "",
  installment: 0,
};

export const EmployeeMenuConfigInitialState: EmployeeConfigMenus = {
  department: "",
  all: false,
  view: false,
  add: false,
  edit: false,
  delete: false,
};
export const ChitCardCreationInitialState: ChitCardCreation = {
  searchcustomer: "",
  customermobilenumber: "",
};
export const CardOtpInitialState: CardOtp = {
  cardotp: "",
};

export const CustomerSchemesInitialState: CustomerSchemes = {
  id: 0,
  schemeid: 0,
  groupid: 0,
  schemename: "",
  groupname: "",
  schemeamount: 0,
  groupnumber: 0,
};
