import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { routes, viewroutes } from "../../routes.js";
import { useGlobalContext } from "../../lib/types.ts";

function Header(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [notification, setNotificationOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const location = useLocation();
  const { state } = useLocation();
  const [userRole, setUserRole] = useState();
  const { employeeInfo, setEmployeeInfo, goldRate } =
    useContext(useGlobalContext);

  const _dailyGR = goldRate;
  // Object.keys(goldRate).length > 0 ? JSON.parse(goldRate) : goldRate;
  const _empInfo = employeeInfo;
  // Object.keys(employeeInfo).length > 0
  //   ? JSON.parse(employeeInfo)
  //   : employeeInfo;

  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };
  const notificationToggle = (e) => {
    setNotificationOpen(!notification);
  };
  const getBrand = () => {
    let brandName = "Lists";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    viewroutes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  const navigate = useNavigate();

  const logout = () => {
    setEmployeeInfo(null);
    sessionStorage.clear();
    navigate("/");
  };
  const onChange = () => {
    navigate("/admin/viewcusquery");
  };

  return (
    <Navbar
      color={
        location.pathname.indexOf("full-screen-maps") !== -1 ? "dark" : color
      }
      expand="lg"
      className={
        location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top"
          : "navbar-absolute fixed-top " +
            (color === "transparent" ? "navbar-transparent " : "")
      }
    >
      <div className="navbar-wrapper">
        <div className="navbar-toggle">
          <button
            type="button"
            ref={sidebarToggle}
            className="navbar-toggler"
            onClick={() => openSidebar()}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <NavbarBrand href="#">{getBrand()}</NavbarBrand>
      </div>
      <NavbarToggler onClick={toggle}>
        <span className="navbar-toggler-bar navbar-kebab" />
        <span className="navbar-toggler-bar navbar-kebab" />
        <span className="navbar-toggler-bar navbar-kebab" />
      </NavbarToggler>
      <b className="goldrate-navbar">
        <Nav navbar>
          Gold Rate : ₹ {isNaN(_dailyGR?.goldRate) ? 0 : _dailyGR?.goldRate}
        </Nav>
      </b>
      <b className="goldrate-navbar">
        <Nav navbar>
          Silver Rate : ₹{" "}
          {isNaN(_dailyGR?.silverRate) ? 0 : _dailyGR?.silverRate}
        </Nav>
      </b>
      <Collapse isOpen={isOpen} navbar className="justify-content-end">
        <b className="branchname-navbar">
          <Nav navbar>Branch : {_empInfo?.branchname}</Nav>
        </b>
        <b>
          <Nav navbar>Welcome {_empInfo?.employename}</Nav>
        </b>
        <Nav navbar>
          <Dropdown nav isOpen={dropdownOpen} toggle={(e) => dropdownToggle(e)}>
            <DropdownToggle caret nav>
              <i className="nc-icon nc-settings-gear-65" />
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag="a" onClick={logout}>
                Log Out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default Header;
