import React, {
  useState,
  useEffect,
  useContext,
  createRef,
  useRef,
  useCallback,
} from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
  Popup,
  AccordionTitle,
  AccordionContent,
  Accordion,
  Segment,
  Grid,
  Divider,
  GridRow,
  GridColumn,
  Search,
  Header,
  DimmerDimmable,
  Dimmer,
  Label,
  Input,
  MessageHeader,
  Message,
  Rail,
  Sticky,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  ModalContent,
} from "semantic-ui-react";
import {
  Currency,
  CustomerDetail,
  CustomerSchemes,
  Dropdowns,
  PaymentDetail,
  PaymentHistory,
  Payments,
} from "../lib/dbobjectmodel";
import {
  CustomerSchemesInitialState,
  PaymentDetailsInitialState,
  PaymentInitalState,
} from "../lib/initialState.ts";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ErrorToast,
  maxLengthCheck,
  SuccessToast,
} from "../utils/ProjectToast.js";
import ModelTable from "../components/modeltable.tsx";
import {
  HttpGet,
  HttpKnexGet,
  HttpTrigger,
  useFetch,
} from "../lib/useFetch.tsx";
import { ToastContainer } from "react-toastify";
import { getKnexDropdown } from "../utils/common.tsx";
import { Utility } from "../utils/Utility.js";
import { Result, useGlobalContext } from "../lib/types.ts";
import Customspinner from "../components/Customspinner.tsx";
import CustomTable from "../components/Table/table.tsx";
import { TableHeaders } from "../components/Table/tableheader.js";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

function PaymentMode(props: any) {
  let { state } = useLocation();

  const [PaymentDetails, setPaymentDetails] = useState<PaymentDetail>(
    PaymentDetailsInitialState
  );
  const [activeIndex, setActiveIndex] = useState(-1);
  const [csDimmer, setCsDimmer] = useState<boolean>(false);
  const [machinenames, setMachineNames] = useState<Dropdowns[]>([]);
  const [machinenumbers, setMachineNumbers] = useState<Dropdowns[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payments, setPayments] = useState<Payments>(PaymentInitalState);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);
  const [isMachineNumberLoading, setIsMachineNumberLoading] =
    useState<boolean>(false);
  const { employeeInfo, goldRate } = useContext(useGlobalContext);
  const [currency, setCurrency] = useState<any>();
  const [isAmountIncorrect, setIsAmountIncorrect] = useState<boolean>(true);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [dueAmount, setDueAmount] = useState<boolean>(true);
  const [savedAmount, setSavedAmount] = useState<number>(0);
  const [cashLimit, setCashLimit] = useState<boolean>(false);
  const [cardLimit, setCardLimit] = useState<boolean>(false);
  const [bankLimit, setBankLimit] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [allCurrencies, setAllCurrencies] = useState<any>();
  const [updatedCurrency, setUpdateCurrency] = useState<any>();
  const [spinners, setspinners] = useState<boolean>(false);
  const [listedCustomer, setListedCustomer] = useState<CustomerDetail[]>([]);
  const [customerSchemes, setCustomerSchemes] = useState<CustomerSchemes[]>([
    CustomerSchemesInitialState,
  ]);
  const [isPaymentHistoryOpen, setPaymenyHisportyOpen] =
    useState<boolean>(false);

  const [paymentHistory, setPaymentHistory] = useState<PaymentHistory[]>();
  const [paymentOpen, setpaymentOpen] = useState<boolean>(true);
  const [paymentMessage, setPaymentMessage] = useState<string>("");

  const panelRef = useRef<HTMLDivElement>(null);

  // const { status, statusText, data, error, loading } = useFetch<
  //   CustomerDetail[]
  // >(process.env.REACT_APP_GET_URL_CUSTOMER, [], "");

  const handleAccordionClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    setActiveIndex(index);
    const mode =
      index == 0
        ? "Card Payment"
        : index == 1
        ? "Cash Payment"
        : "Bank Payment";
    let copy = { ...payments };
    copy.paymentmodes = mode;
    setPayments(copy);
  };

  const handlechange = (e: any, actionMeta: any) => {
    if (e.target.type === "text" || e.target.type === "number") {
      setPaymentDetails({ ...PaymentDetails, [e.target.name]: e.target.value });
    } else {
      setPaymentDetails({
        ...PaymentDetails,
        [actionMeta.name]: actionMeta.value,
      });
    }
  };

  const handlePayment = (e: any, actionMeta: any) => {
    if (e.target.type === "text" || e.target.type === "number") {
      setPayments({ ...payments, [e.target.name]: e.target.value });
    } else {
      setPayments({
        ...payments,
        [actionMeta.name]: actionMeta.value,
      });

      if (actionMeta.name === "machinename") {
        loadMachineNames(
          process.env.REACT_APP_GET_ALL_MACHINEnUMBERS_BY_MACHINENAME,
          actionMeta.value
        );
      }
    }
  };

  useEffect(() => {
    const _due =
      Number(isNaN(payments.cardamount) ? 0 : payments.cardamount) +
      Number(isNaN(payments.cashamount) ? 0 : payments.cashamount) +
      Number(isNaN(payments.bankamount) ? 0 : payments.bankamount);
    if (_due > 0 && _due === PaymentDetails?.schemeamount) {
      setDueAmount(false);
    } else {
      setDueAmount(true);
    }
  }, [cardLimit, cashLimit, bankLimit]);

  const handleCardSubmit = () => {
    setSavedAmount((prev) => prev + Number(payments.cardamount));
    setCardLimit(true);
  };
  const handleCashSubmit = () => {
    setCashLimit(true);
  };
  const handleBankSubmit = () => {
    setBankLimit(true);
  };

  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_URL_PAYMENT);
    loadMachineNames(process.env.REACT_APP_GET_ALL_MACHINENAMES);
    getAllCurrencies(process.env.REACT_APP_GET_ALL_CURRENCY);
    setpaymentOpen(false);
    setPaymentMessage("");
  }, []);

  const customerData = (data: any) => {
    console.log(data);
  };

  const handleSavePayment = () => {
    setspinners(true);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // Months are zero-based, so add 1
    payments.monthyear = `${
      Utility.getAllMonths()[currentMonth]
    }-${currentDate.getFullYear()}`;
    payments.cardPayment = Number(payments.cardamount) > 0 ? 1 : 0;
    payments.cashPayment = Number(payments.cashamount) > 0 ? 1 : 0;
    payments.bankPayment = Number(payments.bankamount) > 0 ? 1 : 0;
    payments.cardamount =
      payments.cardamount == "" ? null : payments.cardamount;
    payments.cashamount =
      payments.cashamount == "" ? null : payments.cashamount;
    payments.bankamount =
      payments.bankamount == "" ? null : payments.bankamount;
    payments.denomination =
      Number(payments.cashamount) > 0 ? JSON.stringify(updatedCurrency) : "";
    payments.transactionumber = Math.floor(10000000 + Math.random() * 90000000);
    payments.customerid = PaymentDetails.customerid;
    payments.schemeid = Number(PaymentDetails.paymentscheme?.schemeid);
    payments.groupno = Number(PaymentDetails.paymentscheme?.groupnumber);
    payments.goldrate = Number(goldRate?.goldRate);

    HttpTrigger(String(process.env.REACT_APP_SAVE_PAYMENT), "POST", payments)
      .then((res: any) => {
        console.log(res);

        setTimeout(() => {
          setspinners(false);
        }, 2000);
        if (res.code === 500) {
          ErrorToast("payment is failure, try again after sometime");
        } else {
          setTimeout(() => {
            SuccessToast("payment details saved successfully");
            onReset();
          }, 500);
        }
        setIsNew(false);
      })
      .catch((error) => {
        ErrorToast("Something went wrong, try again after sometime");
      });
  };

  const loadMachineNames = (url: any, id?: number): void => {
    HttpKnexGet(url, id).then((res: any) => {
      setTimeout(() => {
        if (id === undefined) {
          setMachineNames(getKnexDropdown(res));
        } else {
          setMachineNumbers(getKnexDropdown(res));
        }
      }, 500);
    });
  };
  const getAllCurrencies = (url: any): void => {
    HttpKnexGet(url, undefined).then((res: any) => {
      setTimeout(() => {
        setAllCurrencies(res);
      }, 500);
    });
  };

  useEffect(() => {
    let copy = { ...payments };
    const amt = updatedCurrency?.reduce((total, item) => total + item.total, 0);
    setTimeout(() => {
      copy.cashamount = amt;
      setPayments((prev) => copy);
    }, 500);
  }, [updatedCurrency]);

  const loadCurrency = (): void => {
    let sumOfAmount: number = 0;
    let newArray = (allCurrencies || []).map((item) => ({
      ...item,
      total: 0,
    }));
    const _arr: any = [];

    if (PaymentDetails?.groupname != "") {
      newArray.forEach((element, index) => {
        _arr.push(
          <FormGroup widths="equal">
            <FormInput
              type="number"
              id={index}
              name={index}
              fluid={true}
              value={element?.currency}
              readOnly={true}
              icon="rupee"
              iconPosition="left"
              style={{ opacity: "0.6", pointerEvents: "none" }}
            />

            <FormInput
              type="number"
              id={element.currency}
              name={element.currency}
              placeholder="0"
              maxLength={100}
              onInput={maxLengthCheck}
              fluid={true}
              onChange={(e) => {
                const clonedArray = [...newArray];
                const row = clonedArray[index];
                const { name, value } = e.target;
                const _total = Number(name) * Number(value);

                const arraySum = newArray.reduce(
                  (total, item) => total + item.total,
                  0
                );
                const _sum = _total + arraySum;

                const _due =
                  Number(_sum) +
                  Number(isNaN(payments.cardamount) ? 0 : payments.cardamount) +
                  Number(isNaN(payments.bankamount) ? 0 : payments.bankamount);

                newArray[index].total = 0;

                if (arraySum == 0) {
                  sumOfAmount = 0;
                }

                if (
                  _sum <= PaymentDetails?.schemeamount &&
                  // sumOfAmount <= PaymentDetails?.schemeamount &&
                  _due <= PaymentDetails.schemeamount
                ) {
                  row.total = _total;
                  clonedArray[index] = row;
                  newArray = clonedArray;
                  setTimeout(() => {
                    setUpdateCurrency((pre) => newArray);
                    sumOfAmount += _total;
                  }, 500);
                } else {
                  setTimeout(() => {
                    row.total =
                      Number(name) * Number(e.target.value.slice(0, -1));
                    sumOfAmount = row.total;
                    clonedArray[index] = row;
                    newArray = clonedArray;
                    setUpdateCurrency((pre) => newArray);
                    return (e.target.value = e.target.value.slice(0, -1));
                  }, 100);
                }
              }}
              disabled={cashLimit}
            />
          </FormGroup>
        );
      });

      setCurrency(_arr);
    }
  };

  const addDays = (date: any, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  useEffect(() => {
    const maturityDays = 330;
    if (state) {
      setCurrency(null);

      let _data: PaymentDetail = JSON.parse(state?.data);

      let joinedMonth = Utility.getAllMonths()
        .map((item, index) =>
          item.toLocaleLowerCase() ===
          _data?.paymentscheme?.groupname?.split("-")[3].toLocaleLowerCase()
            ? index
            : -1
        )
        .filter((index) => index !== -1)[0];

      const curMonth = new Date().getMonth() + 1;
      console.log(listedCustomer[0]);

      const _receivedInstallment: CustomerSchemes = (
        listedCustomer[0]?.availableschemes || []
      ).filter((item: CustomerSchemes) => {
        return (
          item.groupnumber == _data.paymentscheme?.groupnumber &&
          item.schemeid == _data.paymentscheme?.schemeid
        );
      })[0];

      _data.actualinstallment = curMonth - joinedMonth;
      const _revInstall = _receivedInstallment?.recievedinstallment
        ? _receivedInstallment.recievedinstallment
        : 0;
      _data.pendinginstallment = 11 - _revInstall;
      _data.recievedinstallment = _revInstall;

      _data.recievedby = `${employeeInfo.ecno} - ${employeeInfo.employename}`;
      _data.cardnumber = PaymentDetails.cardnumber;
      _data.schemeamount = Number(_data.paymentscheme?.schemeamount);
      _data.groupname = String(_data.paymentscheme?.groupname);
      _data.groupserialnumber = Number(_data.paymentscheme?.groupnumber);
      _data.weight = String(_data.paymentscheme?.weight);
      console.log(goldRate);

      switch (_data.weight) {
        case "1":
          setTimeout(() => {
            _data.installmentweight = (
              (_data.schemeamount *
                (_data.recievedinstallment ? _data.recievedinstallment : 1)) /
              Number(goldRate?.goldRate)
            ).toFixed(2);
          }, 1000);
          break;
        case "2":
          setTimeout(() => {
            _data.installmentweight = Number(
              _data.schemeamount * Number(goldRate?.goldRate)
            );
          }, 1000);
          break;
        case "3":
          setTimeout(() => {
            _data.installmentweight = 0;
          }, 1000);
          break;
        default:
          setTimeout(() => {
            _data.installmentweight = 0;
          }, 1000);
          break;
      }
      const daysToAdd = maturityDays - _data.createddate.split("-")[2];
      const _mdate = addDays(new Date(_data.createddate), daysToAdd);
      _data.maturitydate = _mdate.toISOString().split("T")[0];

      setTimeout(() => {
        _data.insert_update = 2;
        setPayments({
          ...payments,
          ["customerid"]: _data.customerid,
          ["groupno"]: Number(_data.paymentscheme?.groupnumber),
        });
        setPaymentDetails(_data);
        setIsModalOpen(!isModalOpen);
        setTimeout(() => {
          setIsNew(true);
          getCustomerPaymentHistory(
            String(process.env.REACT_APP_GET_CUSTOMER_PAYMENT_HISTORY),
            `${_data.customerid}~${_data.paymentscheme?.schemeid}~${_data.paymentscheme?.groupnumber}`
          ).then((result) => {
            setTimeout(() => {
              setPaymentHistory(result);
            }, 500);
          });
        }, 1000);
      }, 500);
      if (_data.actualinstallment === _data.recievedinstallment) {
        setpaymentOpen(true);
        setTimeout(() => {
          setPaymentMessage((prev) => "You are update to date with payment.");
          setCardLimit(true);
          setCashLimit(true);
          setBankLimit(true);
          return;
        }, 500);
      }
      if (_receivedInstallment?.twoinstallment === 2) {
        setpaymentOpen(true);
        setTimeout(() => {
          setPaymentMessage(
            (prev) =>
              "You have already paid two installments for this scheme. So you can't pay more than two installments."
          );
          setCardLimit(true);
          setCashLimit(true);
          setBankLimit(true);
          return;
        }, 500);
      }
    }
  }, [state?.data]);

  useEffect(() => {
    setTimeout(() => {
      setIsNew(false);
      setUpdateCurrency(allCurrencies);
      loadCurrency();
    }, 500);
  }, [PaymentDetails]);

  useEffect(() => {
    props.onProcessdata(PaymentDetails);
  });

  useEffect(() => {
    onReset();
  }, [state?.data]);

  const populateCustomer = () => {
    setIsModalOpen(!isModalOpen);
    HttpKnexGet(
      `${process.env.REACT_APP_GET_URL_CUSTOMER}`,
      PaymentDetails?.cardnumber
    )
      .then((res: CustomerDetail) => {
        if (res[0].length === 0) {
          ErrorToast(
            "Please check mobile number!, this customer not registered with us. Please register and try again"
          );
          setIsCustomerLoading(false);
          return;
        } else {
          getCustomerSchemes(
            String(process.env.REACT_APP_GET_CUSTOMER_SCHEMES),
            Number(res[0][0].customerid)
          ).then((result) => {
            setTimeout(() => {
              res[0][0].availableschemes = result.filter((item) => {
                return item.groupnumber >= 1;
              });
              setListedCustomer(res[0]);
            }, 500);
            setIsCustomerLoading(false);
          });
        }
      })
      .catch((err) => {
        setIsCustomerLoading(false);
        ErrorToast("Something went wrong, please try again after sometime!");
      });
  };

  const getCustomerSchemes = (url: any, id: number): Promise<any> => {
    let _res: any;
    return new Promise((resolve) => {
      HttpKnexGet(url, id).then((res: any) => {
        setTimeout(() => {
          if (res.length > 0) {
            _res = res;
          } else {
            _res = [];
          }
          resolve(_res);
        }, 500);
      });
    });
  };

  const getCustomerPaymentHistory = (
    url: any,
    cusandscheme: string
  ): Promise<any> => {
    let _res: any;
    return new Promise((resolve) => {
      HttpKnexGet(url, cusandscheme).then((res: any) => {
        setTimeout(() => {
          if (res.length > 0) {
            res.map((item: any) => {
              item.paiddate = item?.paiddate
                .split("T")[0]
                .split("-")
                .reverse()
                .join("-");
            });

            _res = res;
          } else {
            _res = [];
          }
          resolve(_res);
        }, 500);
      });
    });
  };

  const onReset = () => {
    setIsAmountIncorrect(true);
    setTotalAmount(0);
    setDueAmount(true);
    setSavedAmount(0);
    setCashLimit(false);
    setCardLimit(false);
    setBankLimit(false);
    setActiveIndex(-1);
    setPaymentMessage("");
    setPaymentDetails(PaymentDetailsInitialState);
    window.history.replaceState({}, "");
    state = null;
    setTimeout(() => {
      setPayments(PaymentInitalState);
    }, 1000);
  };

  function formattedCardNumber(e) {
    const { value } = e.target;

    if (value.length < 20) {
      let _value = value.replace(/\D/g, "");
      let formattedValue = _value.match(/.{1,4}/g)?.join(" ") || "";
      return (e.target.value = formattedValue);
    } else {
      return (e.target.value = value.slice(0, -1));
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (PaymentDetails?.cardnumber?.length === 10) {
        handleEnterKeyPress();
      }
    }
  };

  const handleEnterKeyPress = () => {
    populateCustomer();
  };

  const toggle = () => {
    setPaymenyHisportyOpen(!isPaymentHistoryOpen);
  };

  return (
    <>
      {!spinners ? (
        <div className="content" ref={panelRef}>
          <Container className="project-form">
            <Form>
              <Label as="a" color="red" ribbon>
                Scheme Info
              </Label>
              <FormGroup widths="equal">
                <FormInput
                  id="cardnumber"
                  name="cardnumber"
                  placeholder="Enter card number"
                  label="Card Number"
                  type="number"
                  fluid={true}
                  onChange={handlechange}
                  onKeyDown={handleKeyDown}
                  value={PaymentDetails?.cardnumber}
                  maxLength={10}
                  onInput={maxLengthCheck}
                  loading={isCustomerLoading}
                  icon={
                    <Icon
                      name="search"
                      color="blue"
                      circular
                      inverted
                      link
                      disabled={
                        PaymentDetails?.cardnumber?.length === 10 ? false : true
                      }
                      onClick={() => {
                        setTimeout(() => {
                          if (PaymentDetails?.cardnumber?.length === 10) {
                            setIsCustomerLoading(true);
                            setIsModalOpen(!isModalOpen);
                            populateCustomer();
                          }
                        }, 500);
                      }}
                    />
                  }
                />

                <FormInput
                  type="number"
                  id="customerid"
                  name="customerid"
                  placeholder="***"
                  label="Customer Id"
                  fluid={true}
                  value={PaymentDetails?.customerid}
                  readOnly={true}
                />
              </FormGroup>

              <FormGroup widths="equal">
                <FormInput
                  type="text"
                  id="grpname"
                  name="grpname"
                  placeholder="Group name"
                  label="Group Name"
                  fluid={true}
                  value={PaymentDetails?.groupname}
                  readOnly={true}
                />
                <FormInput
                  id="grpnumber"
                  name="grpnumber"
                  placeholder="Group number"
                  label="Group Number"
                  type="text"
                  fluid={true}
                  value={PaymentDetails?.groupserialnumber}
                  readOnly={true}
                />
              </FormGroup>
              <FormField widths="equal">
                {isNew ? (
                  <Popup
                    content="View payment history"
                    position="top center"
                    trigger={
                      <Button
                        icon="th list"
                        content="Payment History"
                        inverted
                        color="blue"
                        fluid={true}
                        size="medium"
                        type="button"
                        id="viewsummary"
                        name="viewsummary"
                        onClick={() => {
                          setPaymenyHisportyOpen(!isPaymentHistoryOpen);
                        }}
                      ></Button>
                    }
                  />
                ) : (
                  ""
                )}
              </FormField>
              <FormGroup widths="equal">
                <FormInput
                  type="number"
                  id="installmentamount"
                  name="installmentamount"
                  placeholder=" 0"
                  label="Installment Amount"
                  fluid={true}
                  value={PaymentDetails?.schemeamount}
                  readOnly={true}
                />
                <FormInput
                  type="number"
                  id="installmentweight"
                  name="installmentweight"
                  placeholder="0.00"
                  label="Installment Weight"
                  fluid={true}
                  value={PaymentDetails?.installmentweight}
                  readOnly={true}
                />
              </FormGroup>

              <FormGroup widths="equal">
                <FormInput
                  type="number"
                  id="actualinstallment"
                  name="actualinstallment"
                  placeholder="0"
                  label="Actual Installment"
                  fluid={true}
                  value={PaymentDetails?.actualinstallment}
                  readOnly={true}
                />
                <FormInput
                  type="date"
                  id="maturitydate"
                  name="maturitydate"
                  label="Maturity Date"
                  fluid={true}
                  value={PaymentDetails?.maturitydate}
                  readOnly={true}
                />
              </FormGroup>

              <FormGroup widths="equal">
                <FormInput
                  type="number"
                  id="recievedinstallment"
                  name="recievedinstallment"
                  placeholder="0"
                  label=" Received Installment"
                  fluid={true}
                  value={PaymentDetails?.recievedinstallment}
                  readOnly={true}
                />
                <FormInput
                  type="number"
                  id="pendinginstallment"
                  name="pendinginstallment"
                  label="Pending Installment"
                  placeholder="0"
                  fluid={true}
                  value={PaymentDetails?.pendinginstallment}
                  readOnly={true}
                />
              </FormGroup>
              <Label as="a" color="red" ribbon>
                Customer Info
              </Label>
              <FormInput
                type="text"
                id="cusname"
                name="cusname"
                placeholder="Name"
                label="Customer Name"
                fluid={true}
                readOnly={true}
                value={PaymentDetails?.customerName}
              />

              <FormGroup widths="equal">
                {/* <FormInput
                  fluid={true}
                  type="number"
                  value={PaymentDetails?.pinCode}
                  label="Pincode"
                  readOnly={true}
                  placeholder="Pincode"
                  id="pincode"
                  name="pincode"
                  maxLength="70"
                /> */}

                <FormInput
                  fluid={true}
                  type="tel"
                  value={PaymentDetails?.mobileNo}
                  label="Mobile Number"
                  readOnly={true}
                  placeholder="Mobile Number"
                  id="mobilenumber"
                  name="mobilenumber"
                  maxLength="10"
                />
                <FormInput
                  type="text"
                  fluid={true}
                  label="Area"
                  value={PaymentDetails?.area}
                  placeholder="Area"
                  id="area"
                  name="area"
                  readOnly={true}
                  maxLength="150"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <FormInput
                  type="text"
                  fluid={true}
                  label="City"
                  value={PaymentDetails?.city}
                  placeholder="City"
                  id="city"
                  name="city"
                  readOnly={true}
                  maxLength="150"
                />

                <FormInput
                  type="text"
                  fluid={true}
                  label="State"
                  value={PaymentDetails?.state}
                  placeholder="State"
                  id="state"
                  name="state"
                  readOnly={true}
                  maxLength="150"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <FormInput
                  type="text"
                  id="recievedby"
                  name="recievedby"
                  placeholder="Recieved By"
                  label=" Recieved By"
                  fluid={true}
                  value={PaymentDetails?.recievedby}
                  readOnly={true}
                />
              </FormGroup>
              <Label as="a" color="red" ribbon>
                Payment Info
              </Label>
              <Accordion fluid styled>
                <AccordionTitle
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  Card Payment
                </AccordionTitle>
                <AccordionContent active={activeIndex === 0}>
                  <div className="field">
                    <span className="d-f-c "> Card Type</span>
                    <div className="radio-input_cs">
                      <label>
                        <input
                          value="Manual"
                          checked={!PaymentDetails?.cardtype}
                          onChange={(e) => {
                            setPaymentDetails({
                              ...PaymentDetails,
                              ["cardtype"]: false,
                            });
                            setPayments({
                              ...payments,
                              ["cardtype"]: false,
                            });
                            setCsDimmer(!csDimmer);
                          }}
                          name="cardtype"
                          id="cardtypeno"
                          type="radio"
                        />
                        <span>Manual</span>
                      </label>
                      <label>
                        <input
                          value="Auto"
                          checked={PaymentDetails?.cardtype}
                          onChange={(e) => {
                            setPaymentDetails({
                              ...PaymentDetails,
                              ["cardtype"]: true,
                            });
                            setPayments({
                              ...payments,
                              ["cardtype"]: true,
                            });
                            setCsDimmer(!csDimmer);
                          }}
                          name="cardtype"
                          id="cardtypeyes"
                          type="radio"
                        />
                        <span>Auto</span>
                      </label>
                      <span className="selection_cs"></span>
                    </div>
                  </div>

                  <Form>
                    <FormSelect
                      type="select"
                      fluid={true}
                      label="Machine Name"
                      value={payments?.machinename}
                      placeholder="Type or select machine name"
                      id="machinename"
                      name="machinename"
                      // error={CustomerDetails?.schemeid == "" ? props.isValid : false}
                      onChange={handlePayment}
                      options={machinenames}
                      search
                      scrolling
                      required
                      disabled={cardLimit}
                    />
                    <FormSelect
                      type="select"
                      fluid={true}
                      label="Machine Number"
                      value={payments?.machinenumber}
                      placeholder="Type or select machine number"
                      id="machinenumber"
                      name="machinenumber"
                      // error={CustomerDetails?.schemeid == "" ? props.isValid : false}
                      onChange={handlePayment}
                      options={machinenumbers}
                      search
                      scrolling
                      required
                      disabled={cardLimit}
                    />
                    <FormInput
                      label="Card Number"
                      type="text"
                      placeholder="1234 5678 9012 3456"
                      maxlength="19"
                      id="cardnumber"
                      name="cardnumber"
                      onInput={formattedCardNumber}
                      onChange={handlePayment}
                      value={payments?.cardnumber}
                      required
                      disabled={payments?.cardtype || cardLimit}
                    />
                    <FormInput
                      type="number"
                      id="cardamount"
                      name="cardamount"
                      placeholder="Amount"
                      label="Amount"
                      fluid={true}
                      value={payments?.cardamount}
                      onChange={(e) => {
                        let copy = { ...payments };
                        const _due =
                          Number(e.target.value) +
                          Number(
                            isNaN(payments.cashamount) ? 0 : payments.cashamount
                          ) +
                          Number(
                            isNaN(payments.bankamount) ? 0 : payments.bankamount
                          );
                        if (
                          Number(e.target.value) <=
                            PaymentDetails?.schemeamount &&
                          _due <= PaymentDetails?.schemeamount
                        ) {
                          copy.cardamount = e.target.value;
                          setTimeout(() => {
                            setPayments((prev) => copy);
                          }, 500);
                        } else {
                          if (copy.cardamount > PaymentDetails?.schemeamount) {
                            setTimeout(() => {
                              return (e.target.value = e.target.value.slice(
                                0,
                                -1
                              ));
                            }, 500);
                          }
                        }
                      }}
                      maxLength={15}
                      onInput={maxLengthCheck}
                      required
                      disabled={cardLimit}
                    />
                    <Button
                      icon="rupee sign"
                      labelPosition="left"
                      color="linkedin"
                      fluid={true}
                      size="big"
                      type="button"
                      id="submit"
                      name="submit"
                      content="Proceed to pay"
                      onClick={handleCardSubmit}
                      disabled={
                        payments.machinename === "" ||
                        payments.machinenumber === "" ||
                        payments.cardamount <= 0 ||
                        cardLimit ||
                        (!payments?.cardtype
                          ? payments.cardnumber.length < 19
                          : false)
                      }
                    ></Button>
                  </Form>
                </AccordionContent>
                <AccordionTitle
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  Cash Payment
                </AccordionTitle>
                <AccordionContent active={activeIndex === 1}>
                  <Segment>
                    <Grid columns={2} relaxed="very">
                      <GridColumn>
                        <p>Denomination</p>
                        {currency}
                      </GridColumn>
                      <GridColumn>
                        <FormInput
                          type="number"
                          id="cashamount"
                          name="cashamount"
                          placeholder="Amount"
                          label="Amount"
                          fluid={true}
                          value={payments?.cashamount}
                          maxLength={15}
                          onInput={maxLengthCheck}
                        />
                      </GridColumn>
                    </Grid>

                    <Divider vertical>=</Divider>
                  </Segment>
                  <Message
                    hidden={isAmountIncorrect}
                    negative
                    icon="warning"
                    header="Please provide valid scheme amount"
                  />
                  <Button
                    icon="rupee sign"
                    labelPosition="left"
                    color="linkedin"
                    fluid={true}
                    size="big"
                    type="button"
                    id="submit"
                    name="submit"
                    content="Proceed to pay"
                    onClick={handleCashSubmit}
                    disabled={
                      payments.cashamount <= 0 ||
                      !isAmountIncorrect ||
                      cashLimit
                    }
                  ></Button>
                </AccordionContent>
                <AccordionTitle
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  Bank Payment
                </AccordionTitle>
                <AccordionContent active={activeIndex === 2}>
                  <FormInput
                    type="text"
                    id="referencenumber"
                    name="referencenumber"
                    placeholder="Reference number"
                    label="Reference Number"
                    fluid={true}
                    value={payments?.referencenumber}
                    onChange={handlePayment}
                    maxLength={100}
                    required
                    disabled={bankLimit}
                  />
                  <FormInput
                    type="text"
                    id="bankname"
                    name="bankname"
                    placeholder="Bank name"
                    label="Bank Name"
                    fluid={true}
                    value={payments?.bankname}
                    onChange={handlePayment}
                    maxLength={100}
                    required
                    disabled={bankLimit}
                  />
                  <FormInput
                    type="number"
                    id="bankcashamount"
                    name="bankcashamount"
                    placeholder="Amount"
                    label="Amount"
                    fluid={true}
                    value={payments?.bankamount}
                    onChange={(e) => {
                      let copy = { ...payments };
                      const _due =
                        Number(
                          isNaN(payments.cardamount) ? 0 : payments.cardamount
                        ) +
                        Number(
                          isNaN(payments.cashamount) ? 0 : payments.cashamount
                        ) +
                        Number(e.target.value);
                      if (
                        Number(e.target.value) <=
                          PaymentDetails?.schemeamount &&
                        _due <= PaymentDetails?.schemeamount
                      ) {
                        copy.bankamount = e.target.value;

                        setTimeout(() => {
                          setPayments((prev) => copy);
                        }, 500);
                      } else {
                        if (copy.bankamount > PaymentDetails?.schemeamount) {
                          setTimeout(() => {
                            return (e.target.value = e.target.value.slice(
                              0,
                              -1
                            ));
                          }, 500);
                        }
                      }
                    }}
                    maxLength={15}
                    onInput={maxLengthCheck}
                    required
                    disabled={bankLimit}
                  />
                  <Button
                    disabled={
                      payments.referencenumber === "" ||
                      payments.bankname === "" ||
                      payments.bankamount <= 0 ||
                      bankLimit
                    }
                    icon="rupee sign"
                    labelPosition="left"
                    color="linkedin"
                    fluid={true}
                    size="big"
                    type="submit"
                    id="submit"
                    name="submit"
                    content="Proceed to pay"
                    onClick={handleBankSubmit}
                  ></Button>
                </AccordionContent>
              </Accordion>
            </Form>
            <FormGroup widths="equal" style={{ marginTop: 3 + "%" }}>
              <Table definition>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell />
                    <TableHeaderCell>Amount</TableHeaderCell>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  <TableRow>
                    <TableCell>Card</TableCell>
                    <TableCell>
                      {isNaN(payments.cardamount) ? "" : payments.cardamount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Cash</TableCell>
                    <TableCell>
                      {isNaN(payments.cashamount) ? "" : payments.cashamount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bank</TableCell>
                    <TableCell>
                      {isNaN(payments.bankamount) ? "" : payments.bankamount}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableRow>
                  <TableCell textAlign="center" verticalAlign="middle" as="h2">
                    Total
                  </TableCell>
                  <TableCell>
                    {Number(
                      isNaN(payments.cardamount) ? 0 : payments.cardamount
                    ) +
                      Number(
                        isNaN(payments.cashamount) ? 0 : payments.cashamount
                      ) +
                      Number(
                        isNaN(payments.bankamount) ? 0 : payments.bankamount
                      )}
                  </TableCell>
                </TableRow>
              </Table>
              <Button
                icon="plus"
                labelPosition="left"
                color="linkedin"
                fluid={true}
                size="big"
                type="button"
                id="submit"
                name="submit"
                content="Save"
                onClick={handleSavePayment}
                disabled={
                  dueAmount ||
                  Number(payments.cardamount) +
                    Number(payments.cashamount) +
                    Number(payments.bankamount) <
                    PaymentDetails.schemeamount
                }
              ></Button>
            </FormGroup>
          </Container>
          <ToastContainer />
        </div>
      ) : (
        <Customspinner />
      )}

      {PaymentDetails?.schemeamount > 0 ? (
        <>
          <div id="chat-circle" className="btn btn-raised">
            <div id="chat-overlay"></div>
            <p>scheme amount</p>
            <p>{PaymentDetails?.schemeamount}</p>
          </div>
          <div id="chat-circle-1" className="btn btn-raised">
            <div id="chat-overlay-1"></div>
            <p>Amount to pay</p>
            <p>
              {Number(PaymentDetails.schemeamount) -
                (Number(isNaN(payments.cardamount) ? 0 : payments.cardamount) +
                  Number(isNaN(payments.cashamount) ? 0 : payments.cashamount) +
                  Number(isNaN(payments.bankamount) ? 0 : payments.bankamount))}
            </p>
          </div>
        </>
      ) : (
        ""
      )}

      <ModelTable
        isOpen={isModalOpen}
        onHandleData={customerData}
        data={listedCustomer || []}
        isPayment={true}
      />
      <Modal isOpen={isPaymentHistoryOpen} backdrop={true} size="large">
        <ModalHeader>
          <div>
            <h5>Payment History</h5>
          </div>
          <div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
        </ModalHeader>
        <ModalBody>
          <CustomTable
            datas={paymentHistory || []}
            headers={TableHeaders.paymentHistory}
            isedit={false}
            isdelete={false}
            iswhatapp={false}
            issms={false}
            iscustomer={false}
            isdefault={false}
            isapprove={false}
            isfilter={false}
          />
        </ModalBody>
      </Modal>
      <Modal basic isOpen={paymentOpen} size="small">
        <ModalContent>
          <div className="payment_popup_card">
            <p className="payment_popup_cookieDescription">
              {paymentMessage}
              <br />
              <a href="#">Chit Management System</a>
            </p>

            <div className="payment_popup_buttonContainer">
              <button
                onClick={() => {
                  window.location.reload();
                }}
                className="payment_popup_acceptButton"
              >
                Ok
              </button>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
export default PaymentMode;
