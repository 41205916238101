import { useState, useEffect } from "react";
import {
  type HttpMethods,
  type ApiTriggerResponse,
  type TApiResponse,
  Result,
} from "./types";
import { EmployeDetails } from "./dbobjectmodel";
import { Utility } from "../utils/Utility";
// import * as https from "https";
// import * as paytmcheckSum from "paytmchecksum";

export const useFetch = <T,>(
  url: any,
  initalState: T,
  inputs?: any
): TApiResponse<T> => {
  const [status, setStatus] = useState<Number>(0);
  const [statusText, setStatusText] = useState<String>("");
  const [data, setData] = useState<T>(initalState);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getAPIData = async () => {
    setLoading(true);
    const queryString = new URLSearchParams(inputs).toString();

    try {
      const getUrl = queryString ? `${url}?${queryString}` : url;
      const apiResponse = await fetch(getUrl);
      const json = await apiResponse.json();
      setStatus(apiResponse.status);
      setStatusText(apiResponse.statusText);
      setData(json?.data?.[0]);
    } catch (error) {
      setError(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    getAPIData();
  }, [url]);

  return { status, statusText, data, error, loading };
};

export const HttpTrigger = async <T,>(
  url: string,
  method: HttpMethods,
  data: T
): Promise<Result> => {
  let httploading: boolean = false;
  let httpstatus: Number = 0;
  let httpmessage: any = null;

  const requestOptions = {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  // const http = async () => {
  httploading = true;
  try {
    const apiResponse: any = await fetch(url, requestOptions);
    const json = await apiResponse.json();
    httpmessage = json.message;
    httpstatus = json.status;
  } catch (error) {
    httpmessage = error;
  } finally {
    setTimeout(() => {
      httploading = false;
    }, 500);
  }

  return new Promise((resolve, reject) => {
    resolve(httpmessage);
  });
};

export const HttpGet = async (url: any, data: any) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    // body: JSON.stringify({ data: data }),
  };
  const getUrl = `${url}?data=${data}`;
  const apiResponse = await fetch(getUrl, requestOptions);
  const json = await apiResponse.json();
  return json?.data?.[0];
};

export const HttpKnexGet = async (url: any, data: any) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  const getUrl = `${url}?data=${data}`;
  const apiResponse = await fetch(getUrl, requestOptions);
  const json = await apiResponse.json();
  return json?.data;
};

export const HttpLoginTrigger = async <T,>(
  url: string,
  method: HttpMethods,
  data: T
): Promise<any> => {
  let result: any;

  const requestOptions = {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  const apiResponse: any = await fetch(url, requestOptions);
  result = await apiResponse.json();

  return new Promise((resolve, reject) => {
    resolve(result);
  });
};

export const HttpSKTMTrigger = async (url: any, data: any) => {
  let headers = new Headers();
  headers.set(
    "Authorization",
    "Basic " +
      btoa(
        process.env.REACT_APP_SKTM_CUSTOMER_DETAILS_USERNAME +
          ":" +
          process.env.REACT_APP_SKTM_CUSTOMER_DETAILS_PASSWORD
      )
  );
  headers.set("Content-Type", "application/json");
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  const getUrl = data ? `${url}?${data}` : url;
  const apiResponse = await fetch(getUrl, requestOptions);
  const json = await apiResponse.json();
  return json;
};
export const HttpSKTMOTPTrigger = async (url: any, data: any) => {
  let headers = new Headers();
  headers.set(
    "Authorization",
    "Basic " +
      btoa(
        process.env.REACT_APP_SKTM_OTP_DUPLICATE_CARD_USERNAME +
          ":" +
          process.env.REACT_APP_SKTM_OTP_DUPLICATE_CARD_PASSWORD
      )
  );
  // headers.set("User-Agent", "PostmanRuntime/7.43.0");
  // headers.set("Postman-Token", "2225a5ec-d083-45df-bd98-15a538e4d788");
  headers.set("Access-Control-Allow-Credentials", "true");
  headers.set("Access-Control-Allow-Origin", "*");
  headers.set("Access-Control-Allow-Methods", "DELETE, POST, GET, OPTIONS");
  headers.set(
    "Access-Control-Allow-Headers",
    "Content-Type, Authorization, X-Requested-With"
  );
  const requestOptions = {
    method: "GET",
    headers: headers,
  };
  const getUrl = url;
  const apiResponse = await fetch(getUrl, requestOptions);
  console.log(apiResponse);

  const json = await apiResponse.json();
  return json;
};

export const HttpSKTMPayment = async (amount: any) => {
  // https://business.paytm.com/docs/jscheckout-prerequisites?ref=jsCheckoutdoc
  // https://www.bacancytechnology.com/blog/integrate-paytm-payment-gateway-using-reactjs
  /*
  let paytmParams: any;
  const order_id = String(Math.floor(10000000 + Math.random() * 90000000));
  const mid = "SreeKu73966647319041";
  const mKey = "6QJLTvGM1kG44juX";

  paytmParams.body = {
    requestType: "Payment",
    mid: "SreeKu73966647319041",
    websiteName: "securegw.paytm.in",
    orderId: order_id,
    // "callbackUrl"   : "https://<callback URL to be used by merchant>",
    txnAmount: {
      value: "1.00",
      currency: "INR",
    },
    userInfo: {
      custId: "CUST_001",
    },
  };

  console.log(paytmParams);

  paytmcheckSum
    .generateSignature(JSON.stringify(paytmParams.body), mKey)
    .then(function (checksum) {
      paytmParams.head = {
        signature: checksum,
      };

      var post_data = JSON.stringify(paytmParams);

      var options = {
        /* for Staging */
  // hostname: "https://securegw-edc.paytm.in",
  /* for Production */
  // hostname: 'securegw.paytm.in',
  /*
        port: 443,
        path: `/ecr/payment/request?mid=${mid}&orderId=${order_id}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Content-Length": post_data.length,
        },
      };

      var response = "";
      var post_req = https.request(options, function (post_res) {
        post_res.on("data", function (chunk) {
          response += chunk;
        });

        post_res.on("end", function () {
          console.log("Response: ", response);
        });
      });

      post_req.write(post_data);
      post_req.end();
      return response;
    });
    */

  const url = "http://localhost:3001/V1/payment/proceedtopay";
  let headers = new Headers();
  headers.set("Content-Type", "application/json");
  headers.set("Access-Control-Allow-Origin", "*");

  const _body = {
    head: {
      clientId: "6QJLTvGM1kG44juX",
      reqHash: Utility.generateHaskKey(43),
    },
    body: {
      txnDate: new Date().toISOString(),
      merchantTxnId: String(Math.floor(10000000 + Math.random() * 90000000)),
      txnAmount: String(amount),
      txnNumber: String(Math.floor(10000000 + Math.random() * 90000000)),
      mid: "SreeKu73966647319041",
    },
  };

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(_body),
  };

  console.log(requestOptions);

  const apiResponse = await fetch(url, requestOptions);
  const json = await apiResponse.json();
  return json;
};
