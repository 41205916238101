import React, { createContext } from "react";
import {
  Dropdowns,
  EmployeDetails,
  EmployeeConfigMenus,
  GoldRates,
} from "./dbobjectmodel";

export interface ITodo {
  userId: Number;
  id: Number;
  title: String;
  completed: Boolean;
}

export type TApiResponse<T> = {
  status: Number;
  statusText: String;
  data: T;
  error: any;
  loading: Boolean;
};

export type ApiTriggerResponse = {
  httpstatus: Number;
  httploading: boolean;
  httpmessage: any;
  res: Result;
};

export type ObjectKeys<T> = {
  state: T;
  customSetState: any;
};

export type Result = {
  message: string;
  code: number;
};

export type HttpMethods = "POST" | "PUT" | "DELETE" | "GET";

// type LoggedEmployee = {
//   employeeInfo: EmployeDetails | {};
//   setEmployeeInfo: (empData: EmployeDetails) => void;
// };
// type DropdownContext = {
//   sharedData: Dropdowns[] | [];
//   setSharedData: (newData: Dropdowns[]) => void;
// };

type Dropdowns_Employee = {
  sharedData: Dropdowns[] | [];
  setSharedData: (newData: Dropdowns[]) => void;
  employeeInfo: EmployeDetails;
  setEmployeeInfo: (empData: EmployeDetails) => void;
  menuaccess: EmployeeConfigMenus;
  setMenuAccess: (access: EmployeeConfigMenus) => void;
  goldRate: GoldRates;
  setGoldRate: (goldRate: GoldRates) => void;
};

const initialContext: Dropdowns_Employee = {
  sharedData: [],
  setSharedData: () => {},
  employeeInfo: {},
  setEmployeeInfo: () => {},
  menuaccess: {},
  setMenuAccess: () => {},
  goldRate: {},
  setGoldRate: () => {},
};
// Create the context
export const useGlobalContext =
  createContext<Dropdowns_Employee>(initialContext);
