import React, { useState, useEffect, useContext, useRef } from "react";
import CustomTable from "../../components/Table/table.tsx";
import { TableHeader } from "semantic-ui-react";
import { TableHeaders } from "./tableheader";

function Cardtable(props: any) {
  const { isOpen, data, isPayment, isedit = true, isdelete = true } = props;

  const processData = (data: any) => {
    props.setData(data);
  };

  return (
    <div>
      <CustomTable
        datas={data || []}
        headers={TableHeaders.viewCustomers}
        isedit={isedit}
        isdelete={isdelete}
        iswhatapp={false}
        issms={false}
        iscustomer={false}
        isdefault={false}
        isapprove={false}
        isfilter={false}
        navigateurl="#"
        isselect={false}
        sendData={processData}
        isphoto={true}
        isbutton={true}
        access={undefined}
        ispayment={isPayment}
      />
    </div>
  );
}
export default Cardtable;
