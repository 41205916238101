import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss";
import "./assets/chennaisilks/chennaisilks.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";

import Main from "./main";
import { MyProvider } from "./lib/useContextProvider.tsx";
import { ToastContainer } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <MyProvider>
    <Main></Main>
  </MyProvider>
);
