import React, { useState, useEffect, useRef, useContext } from "react";
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Header,
  Table,
  Button,
  Icon,
  Segment,
  Label,
  Popup,
  FormGroup,
  FormField,
  FormInput,
  Grid,
  GridColumn,
  GridRow,
  Image,
  Placeholder,
  PlaceholderHeader,
  PlaceholderLine,
  PlaceholderParagraph,
  Message,
  Progress,
} from "semantic-ui-react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// import Skeleton from "react-loading-skeleton";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { CustomWhatsappMessages } from "../../utils/whatsapp.js";
import {
  SuccessToast,
  ErrorToast,
  maxLengthCheck,
} from "../../utils/ProjectToast.js";
import { ToastContainer } from "react-toastify";
import Customspinner from "../Customspinner.tsx";
import {
  HttpGet,
  HttpSKTMOTPTrigger,
  HttpSKTMTrigger,
  HttpTrigger,
} from "../../lib/useFetch.tsx";
import { Result, useGlobalContext } from "../../lib/types.ts";
import { ValidateCustomer } from "../../lib/formvalidation.ts";
import { CustomerDetail, EmployeDetails } from "../../lib/dbobjectmodel.ts";
import {
  CustomerInitialState,
  EmployeInitialState,
} from "../../lib/initialState.ts";
import { CardOtp } from "../../lib/dbobjectmodel.ts";
import { CardOtpInitialState } from "../../lib/initialState.ts";
import ChitCard from "../chitcard.tsx";
import { useReactToPrint } from "react-to-print";
function CustomTable({
  headers,
  datas = [],
  isedit,
  isdelete,
  iswhatapp,
  issms,
  iscustomer,
  isapprove = false,
  isdefault = true,
  isfilter = false,
  navigateurl = "",
  deleteurl = "",
  dbtable = "",
  isselect = false,
  onHandleData,
  access,
  isphoto,
  isbutton,
  isloading = true,
  ispayment = false,
  isredeem = false,
  sendData,
}) {
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const location = useLocation();
  const [isDone, setIsDone] = useState(false);
  const [respStatus, setRespStatus] = useState(true);
  const [open, setOpen] = useState(false);
  const [enablebutton, setenablebutton] = useState(false);
  const [deleteId, setDeleteId] = useState<any>();
  const [spinners, setspinners] = useState(false);
  const [txtFilter, setTxtFilter] = useState("");
  const [fullData, setFullData] = useState(datas);
  const [currentData, setCurrentData] = useState(datas);
  const [isSearch, setIsSearch] = useState(false);
  const { employeeInfo, menuaccess } = useContext(useGlobalContext);
  const [delIndex, setDelIndex] = useState();
  const [employee, setEmployee] = useState<EmployeDetails>(EmployeInitialState);
  const [selData, setSelData] = useState<any>();
  const [Otp, setCardOtp] = useState<CardOtp>(CardOtpInitialState);
  const [customerInfo, setCustomerInfo] =
    useState<CustomerDetail>(CustomerInitialState);
  const [openReprint, setOpenReprint] = useState<boolean>(false);
  const [percent, setPrecent] = useState<number>(20);
  const [cardprintdata, setCardPrintData] = useState();

  const redeemData = (data: any, scheme: any, task: string) => {
    sendData({
      customerid: data.customerid,
      schemeid: scheme.schemeid,
      groupnumber: scheme.groupnumber,
      task: task,
      cusschemeid: scheme.id,
      mobileno: data.mobileNo,
    });
  };

  useEffect(() => {
    setFullData(datas);
    setCurrentData(datas);
    setEmployee(employeeInfo);
    setTimeout(() => {
      const checkAll = Object.values(menuaccess).filter(
        (value) => value
      ).length;
      setSelData(checkAll);
    }, 500);
    const interval = setInterval(() => {
      setPrecent((prevCount) => prevCount + 20);
    }, 200);
    setTimeout(() => {
      setIsDone(true);
      clearInterval(interval);
    }, 2000);
  }, []);

  const editData = (data) => {
    navigate(navigateurl, {
      state: { data: JSON.stringify(data), isedit: true },
    });
    if (navigateurl === "#") {
      onHandleData(data);
    }
  };
  const selectData = (data, schemedata) => {
    data.paymentscheme = schemedata;
    navigate(navigateurl, {
      state: { data: JSON.stringify(data), isedit: false },
    });
    if (navigateurl === "#") {
      onHandleData(data);
    }
  };

  const commonDelete = (data, idx) => {
    setDeleteId(data);
    setDelIndex(idx);
    setOpen(true);
  };
  const confirmDelete = () => {
    setOpen(false);
    setspinners(true);
    deleteId.insert_update = 3;
    deleteId.Isdeleted = 1;
    deleteId.deletedBy = employee.ecno;
    HttpTrigger(deleteurl, "DELETE", deleteId).then((res: Result) => {
      setTimeout(() => {
        setspinners(false);
      }, 500);
      if (res.message === "success") {
        SuccessToast("Deleted successfully!");
        datas[delIndex] = { ...datas[delIndex], isdeleted: "No" };
      } else {
        ErrorToast("Something went wrong, please try again after sometime!");
      }
    });
  };

  const Close = () => {
    setOpen(false);
  };
  let my = 0;

  useEffect(() => {
    if (txtFilter === "") {
      setCurrentData(datas);
    } else {
      let _local = [];
      const _data = structuredClone(datas);
      _data.filter((d) => {
        if (Object.values(d).toString().toLowerCase().indexOf(txtFilter) >= 0) {
          _local.push(d);
        }
      });
      setCurrentData(_local);
    }
  }, [txtFilter]);

  const handleChange = (e: any) => {
    setCardOtp({
      ...Otp,
      [e.target.name]: e.target.value,
    });
  };

  const otpSubmit = (data: any) => {
    setspinners(true);
    let _otp = {
      customerid: customerInfo.customerid,
      // mobilenumber: data?.customermobilenumber,
      otp: Number(Otp.cardotp),
      verified: 1,
    };

    HttpTrigger(String(process.env.REACT_APP_CREATE_OTP), "POST", _otp).then(
      (res: Result) => {
        setspinners(false);
        if (res.message === "invalid" && res.code === 200) {
          ErrorToast("In correct OTP, please provide valid OTP");
          return;
        } else {
          SuccessToast("OTP verified successfully");
          setTimeout(() => {
            handlePrintCard(customerInfo);
            setOpen(false);
            setenablebutton(true);
          }, 500);
        }
      }
    );
  };

  const handleOTPSend = (data: any) => {
    setspinners(true);
    const otp = Math.floor(100000 + Math.random() * 900000);
    setCustomerInfo(data);
    let _otp = {
      customerid: data?.customerid,
      otp: otp,
      verified: 0,
      mobileno: Number(data.mobileNo),
      group: data.groupname,
    };

    HttpTrigger(
      String(process.env.REACT_APP_SKTM_DUPLICATE_CARD_OTP),
      "POST",
      _otp
    )
      .then((res: any) => {
        console.log(res);

        setTimeout(() => {
          SuccessToast("OTP sent successfully");
          setOpen(true);
          setspinners(false);
        }, 500);
      })
      .catch((err) => {
        setOpen(true);
        setspinners(false);
        console.log(err);
        ErrorToast("Something went wrong, please try again after sometime!");
      });
  };

  const handlePrintCard = (data: any) => {
    setCustomerInfo(data);
    setTimeout(() => {
      setOpenReprint(true);
    }, 500);
  };

  const handlePrint = useReactToPrint({
    contentRef: contentRef,
    onAfterPrint: () => {
      contentRef.current = null;
      setOpenReprint(false);
      setenablebutton(false);
    },
  });

  return (
    <>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              {isfilter ? (
                <FormGroup>
                  <FormField>
                    <FormInput
                      fluid={true}
                      placeholder="Search..."
                      id="myfilter"
                      name="myfilter"
                      value={txtFilter}
                      onChange={(e) => {
                        setIsSearch(true);
                        setTxtFilter(e.target.value);
                      }}
                    />
                  </FormField>
                </FormGroup>
              ) : (
                ""
              )}
              {!isDone ? (
                <Progress percent={percent} indicating></Progress>
              ) : datas.length > 0 ? (
                <Table celled size="small" color="teal" singleLine>
                  <TableHeader>
                    <TableRow>
                      {headers
                        ? headers.map((header) => {
                            return !header.ishide ? (
                              <TableHeaderCell width={50}>
                                {" "}
                                {header.text}
                              </TableHeaderCell>
                            ) : (
                              ""
                            );
                          })
                        : ""}
                      {(isedit || isdelete) && selData > 1 ? (
                        <TableHeaderCell textAlign="center">
                          {isselect ? "Select" : "Action"}
                        </TableHeaderCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {(!isSearch ? datas || [] : currentData || []).map(
                      (data, idx) => {
                        return (
                          <>
                            <TableRow key={data.id} verticalAlign="top">
                              {headers.map((h, idx) => {
                                return !h.ishide ? (
                                  <TableCell>
                                    {!h.islink ? (
                                      isphoto && idx === 0 ? (
                                        <Image
                                          size="tiny"
                                          src={
                                            data[h.value]
                                              ? data[h.value]
                                              : process.env
                                                  .REACT_APP_DUMMY_IMAGE
                                          }
                                        />
                                      ) : (
                                        data[h.value]
                                      )
                                    ) : (
                                      <Popup
                                        content={data[h.value]}
                                        trigger={
                                          <a
                                            target="_blank"
                                            download={data[h.value]}
                                            rel="noopener noreferrer"
                                            href={data[h.path]}
                                            className="limited-text"
                                          >
                                            {h.text != "GST Notice" ? (
                                              data[h.value]
                                            ) : data[h.value] ? (
                                              <Icon
                                                color="red"
                                                name="attention"
                                              >
                                                {data[h.value]}
                                              </Icon>
                                            ) : (
                                              ""
                                            )}
                                          </a>
                                        }
                                      />
                                    )}
                                  </TableCell>
                                ) : (
                                  ""
                                );
                              })}
                              {(isedit || isdelete) && selData > 1 ? (
                                <TableCell
                                  textAlign="center"
                                  verticalAlign="middle"
                                >
                                  {menuaccess?.all ||
                                  menuaccess?.edit ||
                                  menuaccess?.view ? (
                                    !isbutton ? (
                                      <>
                                        {isselect ? (
                                          <Button
                                            color="green"
                                            size="tiny"
                                            inverted
                                            onClick={() => {
                                              selectData(data, 0);
                                            }}
                                          >
                                            Select
                                          </Button>
                                        ) : (
                                          <Icon
                                            link={true}
                                            size="large"
                                            name={
                                              isselect
                                                ? "selected radio"
                                                : "edit outline"
                                            }
                                            onClick={() => {
                                              editData(data);
                                            }}
                                            className={!isedit ? "d-n" : ""}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <FormGroup widths="equal">
                                          <Button
                                            icon="send"
                                            color="linkedin"
                                            labelPosition="left"
                                            fluid={true}
                                            size="small"
                                            type="button"
                                            id="sendotp"
                                            name="sendotp"
                                            content="Send OTP"
                                            disabled={enablebutton}
                                            onClick={() => {
                                              handleOTPSend(data);
                                            }}
                                          ></Button>
                                          {/* <Button
                                            disabled={!enablebutton}
                                            labelPosition="left"
                                            icon="angle double right"
                                            color="youtube"
                                            fluid={true}
                                            size="small"
                                            type="button"
                                            id="clear"
                                            name="clear"
                                            content="Create And Reprint"
                                            onClick={() => {
                                              handlePrintCard(data);
                                            }}
                                          ></Button> */}
                                        </FormGroup>
                                      </>
                                    )
                                  ) : (
                                    ""
                                  )}

                                  {menuaccess?.all || menuaccess?.delete ? (
                                    <Icon
                                      style={{ marginLeft: 10 }}
                                      link={true}
                                      size="large"
                                      onClick={() => {
                                        commonDelete(data, idx);
                                      }}
                                      color="red"
                                      name="remove circle"
                                      className={!isdelete ? "d-n" : ""}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </TableRow>

                            <TableRow>
                              {ispayment ? (
                                <TableCell colSpan={6}>
                                  <Table attached="bottom" celled selectable>
                                    <TableHeader>
                                      <TableRow>
                                        <TableHeaderCell>
                                          Scheme
                                        </TableHeaderCell>
                                        <TableHeaderCell>Group</TableHeaderCell>
                                        <TableHeaderCell>
                                          Group No
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                          Amount
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                          Created Date
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                          Maturity Date
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                          Select
                                        </TableHeaderCell>
                                      </TableRow>
                                    </TableHeader>

                                    <TableBody>
                                      {data?.availableschemes?.map(
                                        (sch, idx) => {
                                          return (
                                            <>
                                              <TableRow id={sch.id}>
                                                <TableCell>
                                                  {sch.schemename}
                                                </TableCell>
                                                <TableCell>
                                                  {sch.groupname}
                                                </TableCell>
                                                <TableCell>
                                                  {sch.groupnumber}
                                                </TableCell>
                                                <TableCell>
                                                  ₹{sch.schemeamount}
                                                </TableCell>
                                                <TableCell>
                                                  {sch.createddate}
                                                </TableCell>
                                                <TableCell>
                                                  {sch.maturitydate}
                                                </TableCell>
                                                <TableCell>
                                                  {sch.ismatured === 0 ? (
                                                    <Button
                                                      color="green"
                                                      size="tiny"
                                                      inverted
                                                      onClick={() => {
                                                        selectData(data, sch);
                                                      }}
                                                    >
                                                      Select
                                                    </Button>
                                                  ) : (
                                                    <>
                                                      <Button
                                                        labelPosition="left"
                                                        icon="angle double right"
                                                        color="youtube"
                                                        fluid={true}
                                                        size="small"
                                                        type="button"
                                                        id="clear"
                                                        name="clear"
                                                        disabled={
                                                          sch?.linksendcount >=
                                                          3
                                                            ? true
                                                            : false
                                                        }
                                                        content="Send Redeem Link"
                                                        onClick={() => {
                                                          redeemData(
                                                            data,
                                                            sch,
                                                            "redeem"
                                                          );
                                                        }}
                                                      ></Button>
                                                      <br />
                                                      <Button
                                                        labelPosition="left"
                                                        icon="list"
                                                        color="instagram"
                                                        fluid={true}
                                                        onClick={() => {
                                                          redeemData(
                                                            data,
                                                            sch,
                                                            "paymenthistory"
                                                          );
                                                        }}
                                                        content="Payment History"
                                                      ></Button>
                                                    </>
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          );
                                        }
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </TableRow>
                          </>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              ) : (
                <Message
                  success
                  header="No data found, please refine your search"
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      {isdefault ? (
        datas.length == 0 && !iscustomer ? (
          <Segment placeholder>
            <Header icon>
              <Icon name="pdf file outline" />
              No documents are listed for this customer.
            </Header>
            {sessionStorage.getItem("role") !== "Admin" ? (
              <Button
                primary
                onClick={() => {
                  navigate("/admin/paymentremainder");
                }}
              >
                Add Document
              </Button>
            ) : (
              ""
            )}
          </Segment>
        ) : datas.length == 0 ? (
          <Segment placeholder>
            <Header icon>
              <Icon name="pdf file outline" />
              No documents are uploaded.
            </Header>
          </Segment>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {/* <ToastContainer /> */}
      {spinners ? <Customspinner text="Deleting.." /> : ""}
      <Modal isOpen={open} backdrop={true}>
        <ModalHeader>OTP Verification</ModalHeader>
        <ModalBody>
          <FormInput
            type="number"
            fluid={true}
            label=" Enter OTP"
            placeholder="Enter OTP"
            id="cardotp"
            name="cardotp"
            value={Otp.cardotp}
            onChange={handleChange}
            onInput={maxLengthCheck}
            maxLength="6"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="linkedin"
            disabled={Otp?.cardotp?.length < 6 ? true : false}
            onClick={otpSubmit}
          >
            Submit
          </Button>{" "}
          {/* <Button color="teal" onClick={Close}>
            Resend OTP
          </Button> */}
          <Button color="red" onClick={Close}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={openReprint} backdrop={true}>
        <ModalHeader>Print Chit Card</ModalHeader>
        <ModalBody>
          <ChitCard
            mobilenumber={customerInfo.mobileNo}
            customername={customerInfo.customerName}
            city={customerInfo.city}
            innerRef={contentRef}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            icon
            color="linkedin"
            size="big"
            labelPosition="left"
            type="button"
            onClick={() => {
              handlePrint();
            }}
          >
            Print
            <Icon name="print" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default CustomTable;
