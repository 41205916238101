import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import CustomTable from "./Table/table.tsx";
import { TableHeaders } from "./Table/tableheader";

function ModelTable(props: any) {
  const { isOpen, data, isPayment, isselect = false, isedit = false } = props;

  const [open, setOpen] = useState(!isOpen);
  useEffect(() => {
    setTimeout(() => {
      setOpen(!open);
    }, 100);
  }, [isOpen]);

  const processData = (data: any) => {
    // console.log(data);
  };

  const toggle = () => {
    setOpen(false);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Modal isOpen={open} backdrop={true} size="large">
      <ModalHeader>
        <div>
          <h5>Customer</h5>
        </div>
        <div>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={toggle}
          ></button>
        </div>
      </ModalHeader>
      <ModalBody>
        <CustomTable
          datas={data || []}
          headers={TableHeaders.viewCustomers}
          isedit={isedit}
          isdelete={false}
          iswhatapp={false}
          issms={false}
          iscustomer={false}
          isdefault={false}
          isapprove={false}
          isfilter={false}
          navigateurl="#"
          isselect={isselect}
          isphoto={true}
          isbutton={false}
          ispayment={isPayment}
          onHandleData={processData}
        />
      </ModalBody>
      {/* <ModalFooter>
        <Button
          color="green"
          inverted
          onClick={() =>
            setTimeout(() => {
              setOpen(!open);
            }, 200)
          }
        >
          <Icon name="checkmark" /> Close
        </Button>
      </ModalFooter> */}
    </Modal>
  );
}

export default ModelTable;
