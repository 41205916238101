import React, { useContext, useEffect, useState } from "react";

import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
  Message,
  Label,
  Placeholder,
  PlaceholderImage,
  ItemGroup,
  Item,
  ItemImage,
  Grid,
  GridRow,
  GridColumn,
  Image,
  Popup,
  Header,
  AccordionContent,
  Accordion,
  TableRow,
  TableCell,
  Table,
  TableBody,
  AccordionTitle,
  Divider,
  Segment,
  TableHeader,
  TableHeaderCell,
} from "semantic-ui-react";
import { json, useLocation, useNavigate } from "react-router-dom";
import {
  CustomerSchemes,
  Dropdowns,
  EmployeDetails,
  GroupDetail,
  PaymentDetail,
  PaymentHistory,
  Payments,
  type CustomerDetail,
} from "../lib/dbobjectmodel";
import {
  Gender,
  getDropdown,
  getKnexDropdown,
  Relationships,
  Religion,
} from "../utils/common.tsx";
import {
  CustomerInitialState,
  CustomerSchemesInitialState,
  EmployeInitialState,
  firstDays,
  GroupinitialState,
  lastDay,
  PaymentDetailsInitialState,
  PaymentInitalState,
} from "../lib/initialState.ts";
import {
  ErrorToast,
  maxLengthCheck,
  SuccessToast,
} from "../utils/ProjectToast.js";
import { ToastContainer } from "react-toastify";
import { Result, useGlobalContext } from "../lib/types.ts";
import { Utility } from "../utils/Utility.js";
import ModelTable from "../components/modeltable.tsx";
import {
  HttpKnexGet,
  HttpSKTMPayment,
  HttpSKTMTrigger,
  HttpTrigger,
  useFetch,
} from "../lib/useFetch.tsx";
import SecureStorage from "../utils/storageservice.tsx";
import Customspinner from "../components/Customspinner.tsx";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CustomTable from "../components/Table/table.tsx";
import { TableHeaders } from "../components/Table/tableheader.js";

function DailyCollectionPaymentMode(props: any) {
  let { state } = useLocation();
  const location = useLocation();
  const secureStorage = new SecureStorage(sessionStorage);
  const [relationship] = useState(Relationships);
  const [CustomerDetails, setCustomerDetails] =
    useState<CustomerDetail>(CustomerInitialState);
  const { sharedData, employeeInfo, setEmployeeInfo } =
    useContext(useGlobalContext);
  const [schemes, setSchemes] = useState<Dropdowns[]>([]);
  const [branches, setBranches] = useState<Dropdowns[]>([]);
  const [groupDetails, setGroupDetails] =
    useState<GroupDetail>(GroupinitialState);
  const [employee, setEmployee] = useState<EmployeDetails>(EmployeInitialState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listedCustomer, setListedCustomer] = useState<CustomerDetail[]>();
  const [isSelect] = useState<boolean>(true);
  const [customerSchemes, setCustomerSchemes] = useState<CustomerSchemes[]>([
    CustomerSchemesInitialState,
  ]);

  // Payment section
  const [activeIndex, setActiveIndex] = useState(-1);
  const [machinenames, setMachineNames] = useState<Dropdowns[]>([]);
  const [machinenumbers, setMachineNumbers] = useState<Dropdowns[]>([]);
  const [payments, setPayments] = useState<Payments>(PaymentInitalState);
  const [currency, setCurrency] = useState<any>();
  const [cashLimit, setCashLimit] = useState<boolean>(false);
  const [cardLimit, setCardLimit] = useState<boolean>(false);
  const [bankLimit, setBankLimit] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [allCurrencies, setAllCurrencies] = useState<any>();
  const [updatedCurrency, setUpdateCurrency] = useState<any>();
  const [isPaymentHistoryOpen, setPaymenyHisportyOpen] =
    useState<boolean>(false);
  const [paymentHistory, setPaymentHistory] = useState<PaymentHistory[]>();
  const [PaymentDetails, setPaymentDetails] = useState<PaymentDetail>(
    PaymentDetailsInitialState
  );
  const [spinners, setspinners] = useState<boolean>(false);
  const [csDimmer, setCsDimmer] = useState<boolean>(false);
  const [employess, setEmployees] = useState<Dropdowns[]>([]);
  const [customerInfo, setCustomerInfo] = useState<any>([]);

  useEffect(() => {
    setEmployees(
      getDropdown("employee", sharedData).filter(
        (emp) => emp.key != employeeInfo.ecno
      )
    );
  }, []);

  const handleAccordionClick = (e: any, titleProps: any) => {
    const { index } = titleProps;
    setActiveIndex(index);
    const mode =
      index == 0
        ? "Card Payment"
        : index == 1
        ? "Cash Payment"
        : "Bank Payment";
    let copy = { ...payments };
    copy.paymentmodes = mode;
    setPayments(copy);
  };

  const handleChange = (e: any, actionMeta: any) => {
    const { value, name } = e.target;
    let regex = /^[A-Za-z\s]+$/;

    if (e.target.type === "text") {
      let _value: string;
      switch (name) {
        case "street":
        case "taluk":
        case "city":
        case "state":
        case "customername":
        case "nomineename":
        case "area":
          if (value != "" && value.match(regex)) _value = value;
          else {
            setCustomerDetails({
              ...CustomerDetails,
              [e.target.name]: value.slice(0, -1),
            });
            return;
          }
          break;
        default:
          _value = value;
          break;
      }

      setCustomerDetails({
        ...CustomerDetails,
        [e.target.name]: e.target.value,
      });
    } else {
      setCustomerDetails({
        ...CustomerDetails,
        [actionMeta.name]: actionMeta.value,
      });
    }

    if (actionMeta.name === "schemename") {
      const _scheme = schemes[0];

      const amount = 0;
      let _formattedamount: string = "";

      const randomNum = Math.floor(10000000 + Math.random() * 90000000);
      const _grpName =
        employeeInfo.branchname +
        "-" +
        _scheme.text.split("-")[2] +
        "-" +
        amount +
        "-" +
        Utility.getAllMonth()[new Date().getMonth()].value.toLocaleUpperCase() +
        "-" +
        Utility.getCurrentYear();
      setTimeout(() => {
        setCustomerDetails({
          ...CustomerDetails,
          ["schemename"]: actionMeta.value,
          ["groupserialnumber"]: randomNum,
          ["schemeamount"]: amount,
          ["groupname"]: _grpName,
          ["schemeid"]: actionMeta.value,
        });

        setGroupDetails({
          ...groupDetails,
          ["serialnumber"]: randomNum,
          ["groupname"]: _grpName,
          ["installmentamount"]: parseInt(_scheme.text.split("-")[1]),
        });
      }, 200);
    }
  };

  const handlePayment = (e: any, actionMeta: any) => {
    if (e.target.type === "text" || e.target.type === "number") {
      setPayments({ ...payments, [e.target.name]: e.target.value });
    } else {
      setPayments({
        ...payments,
        [actionMeta.name]: actionMeta.value,
      });

      if (actionMeta.name === "machinename") {
        loadMachineNames(
          process.env.REACT_APP_GET_ALL_MACHINEnUMBERS_BY_MACHINENAME,
          actionMeta.value
        );
      }
    }
  };

  const handleCardSubmit = () => {
    HttpSKTMPayment(1200)
      .then((res: any) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    return;
    setCardLimit(true);
  };
  const handleCashSubmit = () => {
    setCashLimit(true);
  };
  const handleBankSubmit = () => {
    setBankLimit(true);
  };

  const loadMachineNames = (url: any, id?: number): void => {
    HttpKnexGet(url, id).then((res: any) => {
      setTimeout(() => {
        if (id === undefined) {
          setMachineNames(getKnexDropdown(res));
        } else {
          setMachineNumbers(getKnexDropdown(res));
        }
      }, 500);
    });
  };
  const getAllCurrencies = (url: any): void => {
    HttpKnexGet(url, undefined).then((res: any) => {
      setTimeout(() => {
        setAllCurrencies(res);
      }, 100);
    });
  };

  useEffect(() => {
    let copy = { ...payments };
    const amt = updatedCurrency?.reduce((total, item) => total + item.total, 0);
    setTimeout(() => {
      copy.cashamount = amt;
      setPayments((prev) => copy);
    }, 500);
  }, [updatedCurrency]);

  function formattedCardNumber(e) {
    const { value } = e.target;

    if (value.length < 20) {
      let _value = value.replace(/\D/g, "");
      let formattedValue = _value.match(/.{1,4}/g)?.join(" ") || "";
      return (e.target.value = formattedValue);
    } else {
      return (e.target.value = value.slice(0, -1));
    }
  }

  const loadCurrency = (): void => {
    let sumOfAmount: number = 0;
    let newArray = (allCurrencies || []).map((item) => ({
      ...item,
      total: 0,
    }));
    const _arr: any = [];

    newArray.forEach((element, index) => {
      _arr.push(
        <FormGroup widths="equal">
          <FormInput
            type="number"
            id={index}
            name={index}
            fluid={true}
            value={element?.currency}
            readOnly={true}
            icon="rupee"
            iconPosition="left"
            style={{ opacity: "0.6", pointerEvents: "none" }}
          />

          <FormInput
            type="number"
            id={element.currency}
            name={element.currency}
            placeholder="0"
            maxLength={100}
            onInput={maxLengthCheck}
            fluid={true}
            onChange={(e) => {
              const clonedArray = [...newArray];
              const row = clonedArray[index];
              const { name, value } = e.target;
              const _total = Number(name) * Number(value);

              row.total = _total;
              clonedArray[index] = row;
              newArray = clonedArray;
              setTimeout(() => {
                setUpdateCurrency((pre) => newArray);
                sumOfAmount += _total;
              }, 500);
            }}
            disabled={cashLimit}
          />
        </FormGroup>
      );
    });
    setTimeout(() => {
      setCurrency(_arr);
    }, 1000);
  };

  const populateCustomer = () => {
    if (CustomerDetails?.mobileNo?.length === 10) {
      setIsModalOpen(!isModalOpen);
      HttpSKTMTrigger(
        `${process.env.REACT_APP_SKTM_CUSTOMER_DETAILS}${CustomerDetails?.mobileNo}`,
        null
      )
        .then((res: any) => {
          setTimeout(() => {
            setListedCustomer(res);
          }, 500);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      ErrorToast("Please enter valid mobile number!");
    }
  };

  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_POST_CUSTOMER);
    loadMachineNames(process.env.REACT_APP_GET_ALL_MACHINENAMES);
    getAllCurrencies(process.env.REACT_APP_GET_ALL_CURRENCY);
    getDropdown("employee", sharedData).filter(
      (emp) => emp.key != employeeInfo.ecno
    );
    setTimeout(() => {
      setUpdateCurrency(allCurrencies);
    }, 500);
  }, []);

  useEffect(() => {
    loadCurrency();
  }, [allCurrencies]);

  const handleSavePayment = () => {
    console.log("done");

    HttpSKTMPayment(1200)
      .then((res: any) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    return;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // Months are zero-based, so add 1
    payments.monthyear = `${
      Utility.getAllMonths()[currentMonth]
    }-${currentDate.getFullYear()}`;
    payments.cardPayment = Number(payments.cardamount) > 0 ? 1 : 0;
    payments.cashPayment = Number(payments.cashamount) > 0 ? 1 : 0;
    payments.bankPayment = Number(payments.bankamount) > 0 ? 1 : 0;
    payments.cardamount =
      payments.cardamount == "" ? null : payments.cardamount;
    payments.cashamount =
      payments.cashamount == "" ? null : payments.cashamount;
    payments.bankamount =
      payments.bankamount == "" ? null : payments.bankamount;
    payments.denomination =
      Number(payments.cashamount) > 0 ? JSON.stringify(updatedCurrency) : "";
    payments.transactionumber = Math.floor(10000000 + Math.random() * 90000000);
    payments.customerid = CustomerDetails.id;
    payments.schemeid = Number(PaymentDetails.paymentscheme?.schemeid);
    payments.groupno = Number(PaymentDetails.paymentscheme?.groupnumber);
    CustomerDetails.CreatedBy = employeeInfo.ecno;
    CustomerDetails.employeeid = employeeInfo.ecno;
    setCustomerInfo([]);
    setCustomerInfo((customerInfo) => [
      ...customerInfo,
      CustomerDetails?.mobileNo,
    ]);
    setCustomerInfo((customerInfo) => [
      ...customerInfo,
      CustomerDetails?.customerName,
    ]);
    setCustomerInfo((customerInfo) => [...customerInfo, CustomerDetails?.city]);
    setCustomerInfo((customerInfo) => [
      ...customerInfo,
      CustomerDetails?.groupserialnumber,
    ]);

    if (CustomerDetails?.joiningecno == "") {
      ErrorToast("Please select employee Id");
      return;
    } else {
      setspinners(true);
      const _customerPromise = delay(500).then((res: any) => {
        return HttpTrigger(
          String(process.env.REACT_APP_GET_POST_CUSTOMER),
          "POST",
          CustomerDetails
        );
      });

      const _paymentPromise = delay(1000).then((res: any) => {
        return HttpTrigger(
          String(process.env.REACT_APP_SAVE_PAYMENT),
          "POST",
          payments
        );
      });

      Promise.all([_customerPromise, _paymentPromise])
        .then((values) => {
          setTimeout(() => {
            setspinners(false);
            setIsNew(false);
          }, 1500);

          values[0].message === "Failed to fetch"
            ? ErrorToast("Something went wrong, try again after sometime")
            : SuccessToast("Details saved successfully");
          setspinners(false);
          onReset();
        })
        .catch((error) => {
          setspinners(false);
          ErrorToast("Something went wrong, try again after sometime");
        });
    }
  };

  const delay = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const onReset = () => {
    setCashLimit(false);
    setCardLimit(false);
    setBankLimit(false);
    setActiveIndex(-1);
    setPaymentDetails(PaymentDetailsInitialState);
    window.history.replaceState({}, "");
    state = null;
    setIsNew(false);
    setTimeout(() => {
      setPayments(PaymentInitalState);
      setCustomerDetails(CustomerInitialState);
    }, 1000);
  };

  useEffect(() => {
    setTimeout(() => {
      const _shareddata: any = JSON.parse(
        JSON.parse(JSON.stringify(secureStorage.getItem("shareddata")))
      );

      const _empIfo = JSON.parse(
        JSON.parse(JSON.stringify(secureStorage.getItem("empinfo")))
      );
      setCustomerDetails({
        ...CustomerDetails,
        ["employeeid"]: `${employeeInfo.ecno} - ${employeeInfo.employename}`,
        ["branch"]: _empIfo.branchid,
      });

      setEmployeeInfo(_empIfo);
      const _dailyScheme = getDropdown("scheme", _shareddata).filter(
        (s: any) => {
          return s.text.split("-")[1] <= 0;
        }
      );

      const _scheme = _dailyScheme[0];

      console.log(_scheme);
      if (_dailyScheme.length === 0) {
        ErrorToast(
          "No daily schemes is available, please create a new daily scheme"
        );
        return;
      } else {
        setSchemes(_dailyScheme);
        const amount = 0;
        const _grpName =
          employeeInfo.branchname +
          "-" +
          _scheme.text.split("-")[2] +
          "-" +
          amount +
          "-" +
          Utility.getAllMonth()[
            new Date().getMonth()
          ].value.toLocaleUpperCase() +
          "-" +
          Utility.getCurrentYear();
        setTimeout(() => {
          setPaymentDetails({
            ...PaymentDetails,
            paymentscheme: {
              schemeid: Number(_scheme.key),
              groupname: _grpName,
              schemeamount: amount,
              groupnumber: 1,
              weight: amount,
            },
          });
        }, 200);
      }
    }, 2000);
  }, [location]);

  useEffect(() => {
    if (state?.data) {
      let _data: CustomerDetail = JSON.parse(state?.data);
      console.log(_data);

      // if (!state.isedit) {
      _data.customerid = _data.id;
      _data.aadharnumber = _data?.aadharnumber
        ? _data.aadharnumber
        : 123456789098;
      // _data.employeeid = employeeInfo.ecno;
      _data.branch = employeeInfo.branchid;
      _data.insert_update = 1;
      _data.schemeid = _data?.schemeid ? _data.schemeid : "";
      _data.cardnumber = _data.mobileNo;
      _data.startdate = firstDays.toISOString().split("T")[0];
      _data.enddate = lastDay.toISOString().split("T")[0];
      _data.employeeid = `${employeeInfo.ecno} - ${employeeInfo.employename}`;
      _data.joiningecno = "";
      _data.schemeid = schemes[0].key;
      _data.schemeamount = 0;
      _data.groupserialnumber = Math.floor(10000000 + Math.random() * 90000000);
      _data.groupname =
        employeeInfo.branchname +
        "-" +
        schemes[0].text.split("-")[2] +
        "-" +
        0 +
        "-" +
        Utility.getAllMonth()[new Date().getMonth()].value.toLocaleUpperCase() +
        "-" +
        Utility.getCurrentYear();
      _data.schemename = schemes[0].text.split("-")[0];
      getCustomerPaymentHistory(
        String(process.env.REACT_APP_GET_CUSTOMER_PAYMENT_HISTORY),
        `${_data.customerid}~${schemes[0].key}~1`
      ).then((result) => {
        setTimeout(() => {
          setIsNew(true);
          setPaymentHistory(result);
        }, 500);
      });
      setTimeout(() => {
        setIsModalOpen(false);
        setCustomerDetails(_data);
      }, 2000);
    }
  }, [state?.data]);

  useEffect(() => {
    props.onReset(false);
    setIsNew(false);
    props.onTrigger(process.env.REACT_APP_GET_POST_CUSTOMER);
    window.history.replaceState({}, "");
    state = null;
    setCustomerDetails((prev) => CustomerInitialState);
    setListedCustomer([]);
    setCustomerSchemes([]);
    setTimeout(() => {
      setCustomerDetails({
        ...CustomerDetails,
        ["employeeid"]: `${employeeInfo.ecno} - ${employeeInfo.employename}`,
        ["branch"]: employeeInfo.branchid,
      });
    }, 500);
  }, [props.reset]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (CustomerDetails?.mobileNo?.length === 10) handleEnterKeyPress();
      else ErrorToast("Please enter valid mobile number!");
    }
  };

  const handleEnterKeyPress = () => {
    populateCustomer();
  };

  const toggle = () => {
    setPaymenyHisportyOpen(!isPaymentHistoryOpen);
  };

  const getCustomerPaymentHistory = (
    url: any,
    cusandscheme: string
  ): Promise<any> => {
    let _res: any;
    return new Promise((resolve) => {
      HttpKnexGet(url, cusandscheme).then((res: any) => {
        setTimeout(() => {
          if (res.length > 0) {
            res.map((item: any) => {
              item.paiddate = item?.paiddate
                .split("T")[0]
                .split("-")
                .reverse()
                .join("-");
            });

            _res = res;
          } else {
            _res = [];
          }
          resolve(_res);
        }, 500);
      });
    });
  };

  return (
    <>
      {!spinners ? (
        <div className="content">
          <Container className="project-form">
            <Form>
              <Label as="a" color="red" ribbon>
                Customer Info
              </Label>
              <FormGroup widths="equal">
                <FormInput
                  fluid={true}
                  type="number"
                  value={CustomerDetails?.mobileNo}
                  label="Mobile Number"
                  error={
                    CustomerDetails?.mobileNo == "" ? props.isValid : false
                  }
                  placeholder="Mobile Number"
                  id="mobileNo"
                  name="mobileNo"
                  onChange={handleChange}
                  onInput={maxLengthCheck}
                  onKeyDown={handleKeyDown}
                  maxLength="10"
                  icon={
                    <Icon
                      name="search"
                      inverted
                      circular
                      link
                      onClick={populateCustomer}
                    />
                  }
                />

                <FormInput
                  type="number"
                  fluid={true}
                  label="Card Number"
                  key="cuscardnumber"
                  value={CustomerDetails?.mobileNo}
                  placeholder="Card Number"
                  id="cardnumber"
                  name="cardnumber"
                  style={{ opacity: "0.6", pointerEvents: "none" }}
                />
              </FormGroup>
              <FormField widths="equal">
                {isNew ? (
                  <Popup
                    content="View payment history"
                    position="top center"
                    trigger={
                      <Button
                        icon="th list"
                        content="Payment History"
                        inverted
                        color="blue"
                        fluid={true}
                        size="medium"
                        type="button"
                        id="viewsummary"
                        name="viewsummary"
                        onClick={() => {
                          setPaymenyHisportyOpen(!isPaymentHistoryOpen);
                        }}
                      ></Button>
                    }
                  />
                ) : (
                  ""
                )}
              </FormField>
              <FormGroup widths="equal">
                <FormInput
                  type="text"
                  fluid={true}
                  label="Customer Name"
                  value={CustomerDetails?.customerName}
                  placeholder="Customer Name"
                  id="customerName"
                  name="customerName"
                  error={
                    CustomerDetails?.customerName == "" ? props.isValid : false
                  }
                  onChange={handleChange}
                  maxLength="150"
                />
                <FormInput
                  type="text"
                  id="relationship"
                  name="relationship"
                  placeholder="Relationship"
                  label="Customer Relationship"
                  fluid={true}
                  maxLength={100}
                  value={CustomerDetails?.relationship}
                  onChange={handleChange}
                  error={
                    CustomerDetails?.relationship == "" ? props.isValid : false
                  }
                />
              </FormGroup>

              <FormGroup roup widths="equal">
                <FormInput
                  fluid={true}
                  type="text"
                  value={CustomerDetails?.doorNo}
                  label="Door Number"
                  error={CustomerDetails?.doorNo == "" ? props.isValid : false}
                  placeholder="Door Number"
                  id="doorNo"
                  name="doorNo"
                  style={{ opacity: "0.6" }}
                  readOnly={true}
                  onInput={maxLengthCheck}
                  maxLength="20"
                />
                <FormInput
                  fluid={true}
                  type="text"
                  value={CustomerDetails?.street}
                  label="Street"
                  error={CustomerDetails?.street == "" ? props.isValid : false}
                  placeholder="Street"
                  id="street"
                  name="street"
                  style={{ opacity: "0.6" }}
                  readOnly={true}
                  maxLength="150"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <FormField>
                  <FormInput
                    type="number"
                    fluid={true}
                    label="Aadhar Number"
                    value={CustomerDetails?.aadharnumber}
                    placeholder="Aadhar Number"
                    id="aadharnumber"
                    name="aadharnumber"
                    error={
                      CustomerDetails?.aadharnumber == ""
                        ? props.isValid
                        : false
                    }
                    style={{ opacity: "0.6" }}
                    readOnly={true}
                    onInput={maxLengthCheck}
                    maxLength="12"
                  />

                  {CustomerDetails?.aadharnumber != "" &&
                  props.isFormatted.includes("AADHAR") ? (
                    <Label basic color="red" pointing="above">
                      Please enter valid AADHAR number
                    </Label>
                  ) : (
                    ""
                  )}
                </FormField>
                <FormInput
                  fluid={true}
                  type="number"
                  value={CustomerDetails?.pinCode}
                  label="Pincode"
                  error={CustomerDetails?.pinCode == "" ? props.isValid : false}
                  placeholder="Pincode"
                  id="pinCode"
                  name="pinCode"
                  style={{ opacity: "0.6" }}
                  readOnly={true}
                  onInput={maxLengthCheck}
                  maxLength="6"
                />
              </FormGroup>
              <FormGroup widths="equal">
                <FormInput
                  type="text"
                  fluid={true}
                  label="Area"
                  value={CustomerDetails?.area}
                  placeholder="Area"
                  id="area"
                  name="area"
                  error={CustomerDetails?.area == "" ? props.isValid : false}
                  style={{ opacity: "0.6" }}
                  readOnly={true}
                  maxLength="150"
                />

                <FormInput
                  type="text"
                  fluid={true}
                  label="Taluk"
                  value={CustomerDetails?.taluk}
                  placeholder="Taluk"
                  id="taluk"
                  name="taluk"
                  error={CustomerDetails?.taluk == "" ? props.isValid : false}
                  style={{ opacity: "0.6" }}
                  readOnly={true}
                  maxLength="150"
                />
              </FormGroup>
              <FormGroup widths={"equal"}>
                <FormInput
                  type="text"
                  fluid={true}
                  label="City"
                  value={CustomerDetails?.city}
                  placeholder="City"
                  id="city"
                  name="city"
                  error={CustomerDetails?.city == "" ? props.isValid : false}
                  style={{ opacity: "0.6" }}
                  readOnly={true}
                  maxLength="150"
                />

                <FormInput
                  type="text"
                  fluid={true}
                  label="State"
                  value={CustomerDetails?.state}
                  placeholder="State"
                  id="state"
                  name="state"
                  error={CustomerDetails?.state == "" ? props.isValid : false}
                  style={{ opacity: "0.6" }}
                  readOnly={true}
                  maxLength="150"
                />
              </FormGroup>
              <FormGroup widths={"equal"}>
                <FormField>
                  <FormInput
                    type="email"
                    fluid={true}
                    label="Email ID"
                    value={CustomerDetails?.email}
                    placeholder="Email ID"
                    id="email"
                    name="email"
                    error={CustomerDetails?.email == "" ? props.isValid : false}
                    style={{ opacity: "0.6" }}
                    readOnly={true}
                    maxLength="200"
                  />
                  {CustomerDetails?.email != "" &&
                  props.isFormatted.includes("email") ? (
                    <Label basic color="red" pointing="above">
                      Please enter valid email
                    </Label>
                  ) : (
                    ""
                  )}
                </FormField>

                <FormSelect
                  type="select"
                  fluid={true}
                  label="Employee ID"
                  value={CustomerDetails?.joiningecno}
                  placeholder="Type or select employee name"
                  id="joiningecno"
                  name="joiningecno"
                  error={CustomerDetails?.joiningecno == "" ? true : false}
                  onChange={handleChange}
                  maxLength="150"
                  options={employess}
                  search
                  scrolling
                  disabled={CustomerDetails?.insert_update > 1 ? true : false}
                />
              </FormGroup>
              <Label as="a" color="red" ribbon>
                Scheme Info
              </Label>
              <FormGroup widths="equal">
                <FormSelect
                  type="select"
                  fluid={true}
                  label="Scheme"
                  value={CustomerDetails?.schemeid}
                  placeholder="Type or select scheme name"
                  id="schemename"
                  name="schemename"
                  maxLength="150"
                  options={schemes}
                  search
                  scrolling
                  style={{ opacity: "0.6" }}
                />

                <FormInput
                  type="number"
                  fluid={true}
                  label="Scheme Amount"
                  value={CustomerDetails?.schemeamount}
                  placeholder="Scheme Amount"
                  id="schemeamount"
                  name="schemeamount"
                  maxLength="50"
                  style={{ opacity: "0.6" }}
                />
              </FormGroup>
              <FormGroup widths="equal">
                <FormInput
                  type="number"
                  fluid={true}
                  label="Group Serial Number"
                  value={CustomerDetails?.groupserialnumber}
                  placeholder="Serial Number"
                  id="groupserialnumber"
                  name="groupserialnumber"
                  maxLength="150"
                  style={{ opacity: "0.6" }}
                />

                <FormInput
                  type="text"
                  fluid={true}
                  label="Group Name"
                  value={CustomerDetails?.groupname}
                  placeholder="Group Name"
                  id="groupname"
                  name="groupname"
                  maxLength="50"
                  style={{ opacity: "0.6" }}
                />
              </FormGroup>
              <Label as="a" color="red" ribbon>
                Nominee Info
              </Label>
              <FormGroup widths={"equal"}>
                <FormInput
                  type="text"
                  fluid={true}
                  label="Name"
                  value={CustomerDetails?.nomineeName}
                  placeholder="Name"
                  id="nomineeName"
                  name="nomineeName"
                  error={
                    CustomerDetails?.nomineeName == "" ? props.isValid : false
                  }
                  onChange={handleChange}
                  maxLength="150"
                />
                <FormInput
                  type="text"
                  id="nomineeRelation"
                  name="nomineeRelation"
                  placeholder="Relationship"
                  label="Relationship"
                  fluid={true}
                  maxLength={100}
                  value={CustomerDetails?.nomineeRelation}
                  onChange={handleChange}
                  error={
                    CustomerDetails?.nomineeRelation == ""
                      ? props.isValid
                      : false
                  }
                />
              </FormGroup>
              <FormInput
                type="number"
                fluid={true}
                label="Mobile Number"
                value={CustomerDetails?.nomineeMobile}
                placeholder="Mobile Number"
                id="nomineeMobile"
                name="nomineeMobile"
                error={
                  CustomerDetails?.nomineeMobile == "" ? props.isValid : false
                }
                onChange={handleChange}
                onInput={maxLengthCheck}
                maxLength="10"
              />

              <Accordion fluid styled>
                <AccordionTitle
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  Card Payment
                </AccordionTitle>
                <AccordionContent active={activeIndex === 0}>
                  <div className="field">
                    <span className="d-f-c "> Card Type</span>
                    <div className="radio-input_cs">
                      <label>
                        <input
                          value="Manual"
                          checked={!PaymentDetails?.cardtype}
                          onChange={(e) => {
                            setPaymentDetails({
                              ...PaymentDetails,
                              ["cardtype"]: false,
                            });
                            setPayments({
                              ...payments,
                              ["cardtype"]: false,
                            });
                            setCsDimmer(!csDimmer);
                          }}
                          name="cardtype"
                          id="cardtypeno"
                          type="radio"
                        />
                        <span>Manual</span>
                      </label>
                      <label>
                        <input
                          value="Auto"
                          checked={PaymentDetails?.cardtype}
                          onChange={(e) => {
                            setPaymentDetails({
                              ...PaymentDetails,
                              ["cardtype"]: true,
                            });
                            setPayments({
                              ...payments,
                              ["cardtype"]: true,
                            });
                            setCsDimmer(!csDimmer);
                          }}
                          name="cardtype"
                          id="cardtypeyes"
                          type="radio"
                        />
                        <span>Auto</span>
                      </label>
                      <span className="selection_cs"></span>
                    </div>
                  </div>

                  <Form>
                    <FormSelect
                      type="select"
                      fluid={true}
                      label="Machine Name"
                      value={payments?.machinename}
                      placeholder="Type or select machine name"
                      id="machinename"
                      name="machinename"
                      // error={CustomerDetails?.schemeid == "" ? props.isValid : false}
                      onChange={handlePayment}
                      options={machinenames}
                      search
                      scrolling
                      required
                      disabled={cardLimit}
                    />
                    <FormSelect
                      type="select"
                      fluid={true}
                      label="Machine Number"
                      value={payments?.machinenumber}
                      placeholder="Type or select machine number"
                      id="machinenumber"
                      name="machinenumber"
                      // error={CustomerDetails?.schemeid == "" ? props.isValid : false}
                      onChange={handlePayment}
                      options={machinenumbers}
                      search
                      scrolling
                      required
                      disabled={cardLimit}
                    />
                    <FormInput
                      label="Card Number"
                      type="text"
                      placeholder="1234 5678 9012 3456"
                      maxlength="19"
                      id="cardnumber"
                      name="cardnumber"
                      onInput={formattedCardNumber}
                      onChange={handlePayment}
                      value={payments?.cardnumber}
                      required
                      disabled={payments?.cardtype || cardLimit}
                    />
                    <FormInput
                      type="number"
                      id="cardamount"
                      name="cardamount"
                      placeholder="Amount"
                      label="Amount"
                      fluid={true}
                      value={payments?.cardamount}
                      onChange={handlePayment}
                      maxLength={15}
                      onInput={maxLengthCheck}
                      required
                      disabled={cardLimit}
                    />
                    <Button
                      icon="rupee sign"
                      labelPosition="left"
                      color="linkedin"
                      fluid={true}
                      size="big"
                      type="button"
                      id="submit"
                      name="submit"
                      content="Proceed to pay"
                      onClick={handleCardSubmit}
                      disabled={
                        payments.machinename === "" ||
                        payments.machinenumber === "" ||
                        payments.cardamount <= 0 ||
                        CustomerDetails.email.length <= 0 ||
                        cardLimit ||
                        (!payments?.cardtype
                          ? payments.cardnumber.length < 19
                          : false)
                      }
                    ></Button>
                  </Form>
                </AccordionContent>
                <AccordionTitle
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  Cash Payment
                </AccordionTitle>
                <AccordionContent active={activeIndex === 1}>
                  <Segment>
                    <Grid columns={2} relaxed="very">
                      <GridColumn>
                        <p>Denomination</p>
                        {currency}
                      </GridColumn>
                      <GridColumn>
                        <FormInput
                          type="number"
                          id="cashamount"
                          name="cashamount"
                          placeholder="Amount"
                          label="Amount"
                          fluid={true}
                          value={payments?.cashamount}
                          maxLength={15}
                          onInput={maxLengthCheck}
                        />
                      </GridColumn>
                    </Grid>

                    <Divider vertical>=</Divider>
                  </Segment>
                  <Button
                    icon="rupee sign"
                    labelPosition="left"
                    color="linkedin"
                    fluid={true}
                    size="big"
                    type="button"
                    id="submit"
                    name="submit"
                    content="Proceed to pay"
                    onClick={handleCashSubmit}
                    disabled={
                      payments?.cashamount <= 0 ||
                      cashLimit ||
                      CustomerDetails.email.length <= 0
                    }
                  ></Button>
                </AccordionContent>
                <AccordionTitle
                  active={activeIndex === 2}
                  index={2}
                  onClick={handleAccordionClick}
                >
                  <Icon name="dropdown" />
                  Bank Payment
                </AccordionTitle>
                <AccordionContent active={activeIndex === 2}>
                  <FormInput
                    type="text"
                    id="referencenumber"
                    name="referencenumber"
                    placeholder="Reference number"
                    label="Reference Number"
                    fluid={true}
                    value={payments?.referencenumber}
                    onChange={handlePayment}
                    maxLength={100}
                    required
                  />
                  <FormInput
                    type="text"
                    id="bankname"
                    name="bankname"
                    placeholder="Bank name"
                    label="Bank Name"
                    fluid={true}
                    value={payments?.bankname}
                    onChange={handlePayment}
                    maxLength={100}
                    required
                  />
                  <FormInput
                    type="number"
                    id="bankamount"
                    name="bankamount"
                    placeholder="Amount"
                    label="Amount"
                    fluid={true}
                    value={payments?.bankamount}
                    onChange={handlePayment}
                    maxLength={15}
                    onInput={maxLengthCheck}
                    required
                  />
                  <Button
                    disabled={
                      payments.referencenumber === "" ||
                      payments.bankname === "" ||
                      payments.bankamount <= 0 ||
                      CustomerDetails.email.length <= 0 ||
                      bankLimit
                    }
                    icon="rupee sign"
                    labelPosition="left"
                    color="linkedin"
                    fluid={true}
                    size="big"
                    type="submit"
                    id="submit"
                    name="submit"
                    content="Proceed to pay"
                    onClick={handleBankSubmit}
                  ></Button>
                </AccordionContent>
              </Accordion>
              <ToastContainer />
            </Form>
            <FormGroup widths="equal" style={{ marginTop: 3 + "%" }}>
              <Table definition>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell />
                    <TableHeaderCell>Amount</TableHeaderCell>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  <TableRow>
                    <TableCell>Card</TableCell>
                    <TableCell>
                      {isNaN(payments.cardamount) ? "" : payments.cardamount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Cash</TableCell>
                    <TableCell>
                      {isNaN(payments.cashamount) ? "" : payments.cashamount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bank</TableCell>
                    <TableCell>
                      {isNaN(payments.bankamount) ? "" : payments.bankamount}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableRow>
                  <TableCell textAlign="center" verticalAlign="middle" as="h2">
                    Total
                  </TableCell>
                  <TableCell>
                    {Number(
                      isNaN(payments.cardamount) ? 0 : payments.cardamount
                    ) +
                      Number(
                        isNaN(payments.cashamount) ? 0 : payments.cashamount
                      ) +
                      Number(
                        isNaN(payments.bankamount) ? 0 : payments.bankamount
                      )}
                  </TableCell>
                </TableRow>
              </Table>
              <Button
                icon="plus"
                labelPosition="left"
                color="linkedin"
                fluid={true}
                size="big"
                type="button"
                id="submit"
                name="submit"
                content="Save"
                onClick={handleSavePayment}
                // disabled={!cardLimit && !cashLimit && !bankLimit}
              ></Button>
            </FormGroup>
          </Container>
          <ModelTable
            isOpen={isModalOpen}
            data={listedCustomer || []}
            isselect={isSelect}
            isedit={isSelect}
          />
        </div>
      ) : (
        <Customspinner />
      )}

      <Modal isOpen={isPaymentHistoryOpen} backdrop={true} size="large">
        <ModalHeader>
          <div>
            <h5>Payment History</h5>
          </div>
          <div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
        </ModalHeader>
        <ModalBody>
          <CustomTable
            datas={paymentHistory || []}
            headers={TableHeaders.paymentHistory}
            isedit={false}
            isdelete={false}
            iswhatapp={false}
            issms={false}
            iscustomer={false}
            isdefault={false}
            isapprove={false}
            isfilter={false}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
export default DailyCollectionPaymentMode;
