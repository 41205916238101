import React, { useRef, useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { useReactToPrint } from "react-to-print";
interface Props {
  mobilenumber: number;
  customername: string;
  city: string;
  groupno: number;
  innerRef: any;
}

function ChitCard(props: Props) {
  const [innerRef, setInnerRef] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(0);
  const [customername, setCustomername] = useState("");
  const [groupno, setGroupNo] = useState(0);
  const [city, setCity] = useState("");
  // const { mobilenumber, customername, city, innerRef } = props;
  useEffect(() => {
    setInnerRef(props.innerRef);
    setMobileNumber(Number(props.mobilenumber));
    setCustomername(props.customername);
    setCity(props.city);
    setGroupNo(props.groupno ? props.groupno : 0);
  }, [props]);

  return (
    <div className="chitcardgrid align__item" ref={innerRef}>
      <div className="card_chit">
        <header className="card__header">
          <h3 className="card__title">SKTM Chit</h3>
          <QRCodeSVG
            value={String(mobileNumber)}
            size={50}
            level="H"
            className="card__logo"
          />
        </header>

        <form action="" method="post" className="form">
          <div className="card__number form__field">
            <label htmlFor="card__number" className="card__number__label">
              Card Number
            </label>
            <input
              type="text"
              id="card__number"
              className="card__number__input"
              value={String(mobileNumber)}
            />
          </div>

          {/* <div className="card__ccv form__field">
            <label htmlFor="card__number" className="card__number__label">
              Group
            </label>
            <input
              type="text"
              id="card__number"
              className="card__number__input"
              value={groupno}
            />
          </div> */}

          <div className="card__expiration form__field">
            <label htmlFor="card__number" className="card__number__label">
              Name
            </label>
            <input
              type="text"
              id="card__name"
              className="card__number__input"
              value={customername}
            />
          </div>

          <div className="card__ccv form__field">
            <label htmlFor="">City</label>
            <input type="text" className="card__ccv__input" value={city} />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChitCard;
