import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar, Pie, Doughnut } from "react-chartjs-2";
import DashboardTiles from "../components/dashboardtiles.tsx";
import { HttpKnexGet, useFetch } from "../lib/useFetch.tsx";
import Skeleton from "react-loading-skeleton";

ChartJS.register(...registerables);
function Dashboard() {
  const [chartGroup, setchartGroup] = useState<any>({
    labels: ["Loading..."],
    datasets: [{ data: [] }],
  });
  const [chartScheme, setchartScheme] = useState<any>({
    labels: ["Loading..."],
    datasets: [{ data: [] }],
  });
  const [chartOption] = useState({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    options: {
      scales: {
        x: {
          stacked: true,
        },
        yAxes: [{ ticks: { min: 0 } }],
      },
    },
  });

  useEffect(() => {
    HttpKnexGet(`${process.env.REACT_APP_GET_CAHRTS}`, undefined).then(
      (res: any) => {
        if (res.length === 0) return;
        const _group = res[0];
        const _scheme = res[1];
        let graphGroupData = {
          labels: _group.map((item: any) => item.group) ?? [],
          datasets: [
            {
              label: "Group count by month",
              data: _group.map((item: any) => item.total) ?? [],
              borderWidth: 1,
              minBarLength: 5,
            },
          ],
        };
        let graphSchemeData = {
          labels: _scheme.map((item: any) => item.schemename) ?? [],
          datasets: [
            {
              label: "Schemes",
              data: _scheme.map((item: any) => item.schemeamount) ?? [],
              borderWidth: 1,
              minBarLength: 5,
            },
          ],
        };

        setTimeout(() => {
          setchartGroup(graphGroupData);
          setchartScheme(graphSchemeData);
        }, 1000);
      }
    );
  }, []);

  return (
    <div className="content">
      <Row>
        <DashboardTiles />
      </Row>
      <Row>
        <Col md="6">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Group</CardTitle>
            </CardHeader>
            <CardBody>
              {chartGroup ? (
                <Bar data={chartGroup || []} options={chartOption} />
              ) : (
                <Skeleton count={5} />
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card>
            <CardHeader>
              <CardTitle tag="h5">Scheme</CardTitle>
            </CardHeader>
            <CardBody>
              {chartScheme ? (
                <Pie data={chartScheme || []} options={chartOption} />
              ) : (
                <Skeleton count={5} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
