import React, { useEffect, useState } from "react";
import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
} from "semantic-ui-react";
import "react-toastify/dist/ReactToastify.css";
import ModelTable from "../components/modeltable.tsx";
import { ToastBody } from "reactstrap";
import { ToastContainer } from "react-toastify";
import Customspinner from "../components/Customspinner.tsx";
import {
  ErrorToast,
  maxLengthCheck,
  SuccessToast,
} from "../utils/ProjectToast.js";
import { ChitCardCreation, CustomerDetail } from "../lib/dbobjectmodel.ts";
import {
  ChitCardCreationInitialState,
  CustomerInitialState,
} from "../lib/initialState.ts";
import {
  HttpGet,
  HttpKnexGet,
  HttpSKTMTrigger,
  HttpTrigger,
  useFetch,
} from "../lib/useFetch.tsx";
import Cardtable from "../components/Table/cardtable.tsx";
import CustomerDetails from "./customerdetail.tsx";
function ChitCard(props: any) {
  const [ChitCardDetails, setChitCardDetails] = useState<ChitCardCreation>(
    ChitCardCreationInitialState
  );
  const [isValid, setisValid] = useState(false);
  const [spinners, setspinners] = useState(false);
  const [Buttontext, setButtontext] = useState("save");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listedCustomer, setListedCustomer] = useState<CustomerDetail[]>();
  const [searchCustomer, setSearchCustomer] = useState<boolean>(false);
  const [CustomerDetails] = useState<CustomerDetail>(CustomerInitialState);

  const handleChange = (e: any, actionMeta: any) => {
    if (e.target.value.length > 0 && e.target.value.length < 10) {
      setSearchCustomer(true);
    }

    setChitCardDetails({
      ...ChitCardDetails,
      [e.target.name]: e.target.value,
    });
  };

  const populateCustomer = () => {
    setIsModalOpen(!isModalOpen);
    HttpKnexGet(
      `${process.env.REACT_APP_GET_URL_CUSTOMER}`,
      ChitCardDetails?.customermobilenumber
    )
      .then((res: any) => {
        setTimeout(() => {
          setSearchCustomer(false);
          setListedCustomer(res[0]);
        }, 500);
      })
      .catch((err) => {
        setSearchCustomer(false);
        ErrorToast("Something went wrong, please try again after sometime!");
      });
  };

  useEffect(() => {
    if (ChitCardDetails?.customermobilenumber.length === 10) {
      populateCustomer();
    }
    return () => {
      setListedCustomer([]);
    };
  }, [ChitCardDetails?.customermobilenumber]);

  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              type="number"
              value={ChitCardDetails?.customermobilenumber}
              label="Mobile Number"
              error={
                ChitCardDetails?.customermobilenumber == ""
                  ? props.isValid
                  : false
              }
              placeholder="Mobile Number"
              id="customermobilenumber"
              name="customermobilenumber"
              onChange={handleChange}
              onInput={maxLengthCheck}
              maxLength="10"
              loading={searchCustomer}
            />
          </FormGroup>

          {ChitCardDetails.customermobilenumber.length === 10 ? (
            <Cardtable
              isOpen={isModalOpen}
              data={listedCustomer || []}
              ispayment={false}
              isedit={true}
              isdelete={false}
            />
          ) : (
            ""
          )}
        </Form>
        <ToastContainer />
      </Container>
    </div>
  );
}
export default ChitCard;
