import { CustomerDetail } from "./dbobjectmodel";

export const Validatescheme = {
  // entrynumber: "",
  schemename: "",
  installmentamount: "",
  yellowgoldwastage: "",
  antiqgoldwastage: "",
  diamondmc: "",
  diamondcent: "",
  silvermc: "",
  giftarticlemrp: "",
  code: "",
};

export const ValidateCustomer = {
  customerName: "",
  // cardnumber: "",
  // aadharnumber: "",
  branch: "",
  doorNo: "",
  street: "",
  pincode: "",
  mobileNo: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  email: "",
  nomineeRelation: "",
  relationship: "",
  nomineeMobile: "",
  schemeid: "",
  joiningecno: "",
  // customerrelationship: "",
  // schmemename:"",
  // schemeamount:"",
  // groupserialnumber:"",
  // groupname:"",
};

export const ValidateConcern = {
  companycode: "",
  companyname: "",
  companytype: "",
  ownerpartnername: "",
  doornumber: "",
  street: "",
  area: "",
  taluk: "",
  city: "",
  state: "",
  country: "India",
  pincode: "",
  mobilenumber: "",
  landlinenumber: "",
  faxnumber: "",
  gstn: "",
  pan: "",
  msme: "",
};

export const ValidateUserRoles = {
  concern: "",
  userrole: "",
};

export const ValidateChitGift = {
  cardnumber: "",
  gifttype: "",
  barcode: "",
};

export const ValidateConfigMenus = {
  employeeid: "",
  menus: "",
};

export const Validate = <T, M>(validateObject: T, dataObject: M) => {
  let _isValid = false;
  const _validate: any = validateObject;
  const formInputs: any = dataObject;

  Object.keys(_validate).map((v) => {
    if (_validate[v] === "" && formInputs[v] === _validate[v]) {
      console.log(v);

      _isValid = true;
      return;
    }
  });
  return _isValid;
};
