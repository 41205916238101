import React, { useState, useEffect } from "react";
import { FormGroup, Button, FormField } from "semantic-ui-react";
import { EmployeeConfigMenus } from "../lib/dbobjectmodel";

type ButtonProps = {
  handleSaveClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    trigger: String
  ) => void;
  handleResetClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  trigger: String;
  access: EmployeeConfigMenus;
};

function Buttons({
  handleSaveClick,
  handleResetClick,
  trigger,
  access,
}: ButtonProps) {
  const [IsDisabled, setIsDisabled] = useState(true);
  useEffect(() => {
    if (access?.all || access?.add || access?.edit) {
      setTimeout(() => {
        setIsDisabled(false);
      }, 500);
    } else {
      setTimeout(() => {
        setIsDisabled(true);
      }, 500);
    }
  }, [access?.menuname]);

  return (
    <FormGroup widths="equal" style={{ marginTop: 3 + "%" }}>
      <FormField>
        <Button
          icon="plus"
          labelPosition="left"
          color="linkedin"
          fluid={true}
          size="big"
          type="button"
          id="submit"
          name="submit"
          content="Save"
          disabled={IsDisabled}
          onClick={(e) => {
            handleSaveClick(e, trigger);
          }}
        ></Button>
      </FormField>
      <FormField>
        <Button
          color="grey"
          fluid={true}
          size="big"
          type="button"
          id="clear"
          name="clear"
          icon="redo"
          labelPosition="left"
          content="Clear"
          disabled={IsDisabled}
          onClick={handleResetClick}
        ></Button>
      </FormField>
    </FormGroup>
  );
}

export default Buttons;
