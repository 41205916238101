import React, { useContext, useEffect, useState } from "react";

import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
  Message,
  Label,
  Placeholder,
  PlaceholderImage,
  ItemGroup,
  Item,
  ItemImage,
  Grid,
  GridRow,
  GridColumn,
  Image,
  Popup,
  Header,
} from "semantic-ui-react";
import { json, useLocation, useNavigate } from "react-router-dom";
import {
  CustomerSchemes,
  Dropdowns,
  EmployeDetails,
  GroupDetail,
  SchemeDetail,
  type CustomerDetail,
} from "../lib/dbobjectmodel";
import {
  Gender,
  getDropdown,
  Relationships,
  Religion,
} from "../utils/common.tsx";
import {
  CustomerInitialState,
  CustomerSchemesInitialState,
  EmployeInitialState,
  firstDays,
  GroupinitialState,
  lastDay,
} from "../lib/initialState.ts";
import { ErrorToast, maxLengthCheck } from "../utils/ProjectToast.js";
import { ToastContainer } from "react-toastify";
import { useGlobalContext } from "../lib/types.ts";
import { Utility } from "../utils/Utility.js";
import ModelTable from "../components/modeltable.tsx";
import { HttpKnexGet, HttpSKTMTrigger, useFetch } from "../lib/useFetch.tsx";
import SecureStorage from "../utils/storageservice.tsx";

function CustomerDetails(props: any) {
  let { state } = useLocation();
  const location = useLocation();
  const secureStorage = new SecureStorage(sessionStorage);
  const [relationship] = useState(Relationships);
  const [CustomerDetails, setCustomerDetails] =
    useState<CustomerDetail>(CustomerInitialState);
  const { sharedData, employeeInfo, setEmployeeInfo } =
    useContext(useGlobalContext);
  const [schemes, setSchemes] = useState<Dropdowns[]>([]);
  const [employess, setEmployees] = useState<Dropdowns[]>([]);
  const [groupDetails, setGroupDetails] =
    useState<GroupDetail>(GroupinitialState);
  const [employee, setEmployee] = useState<EmployeDetails>(EmployeInitialState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listedCustomer, setListedCustomer] = useState<CustomerDetail[]>();
  const [isSelect] = useState<boolean>(true);
  const [customerSchemes, setCustomerSchemes] = useState<CustomerSchemes[]>([
    CustomerSchemesInitialState,
  ]);

  const handleChange = (e: any, actionMeta: any) => {
    const { value, name } = e.target;
    let regex = /^[A-Za-z\s]+$/;

    if (e.target.type === "text") {
      let _value: string;
      switch (name) {
        case "street":
        case "taluk":
        case "city":
        case "state":
        case "customername":
        case "nomineename":
        case "area":
          if (value != "" && value.match(regex)) _value = value;
          else {
            setCustomerDetails({
              ...CustomerDetails,
              [e.target.name]: value.slice(0, -1),
            });
            return;
          }
          break;
        default:
          _value = value;
          break;
      }

      setCustomerDetails({
        ...CustomerDetails,
        [e.target.name]: e.target.value,
      });
    } else {
      setCustomerDetails({
        ...CustomerDetails,
        [actionMeta.name]: actionMeta.value,
      });
    }

    if (actionMeta.name === "schemename") {
      const _scheme = schemes?.filter((s) => {
        return s.key === actionMeta.value;
      })[0];

      const amount = parseInt(_scheme.text.split("-")[1]);
      let _formattedamount: string = "";

      if (amount < 100000) {
        _formattedamount = `${amount / 1000}K`;
      } else if (amount >= 100000) {
        _formattedamount = `${amount / 100000}L`;
      }

      const randomNum = Math.floor(10000000 + Math.random() * 90000000);
      const _grpName =
        employeeInfo.branchname +
        "-" +
        _scheme.text.split("-")[2] +
        "-" +
        _formattedamount +
        "-" +
        Utility.getAllMonth()[new Date().getMonth()].value.toLocaleUpperCase() +
        "-" +
        Utility.getCurrentYear();
      setTimeout(() => {
        setCustomerDetails({
          ...CustomerDetails,
          ["schemename"]: actionMeta.value,
          ["groupserialnumber"]: randomNum,
          ["schemeamount"]: amount,
          ["groupname"]: _grpName,
          ["schemeid"]: actionMeta.value,
        });

        setGroupDetails({
          ...groupDetails,
          ["serialnumber"]: randomNum,
          ["groupname"]: _grpName,
          ["installmentamount"]: parseInt(_scheme.text.split("-")[1]),
        });
      }, 200);
    }
  };

  const populateCustomer = () => {
    if (CustomerDetails?.mobileNo?.length === 10) {
      setIsModalOpen(!isModalOpen);
      HttpSKTMTrigger(
        `${process.env.REACT_APP_SKTM_CUSTOMER_DETAILS}${CustomerDetails?.mobileNo}`,
        null
      )
        .then((res: any) => {
          setTimeout(() => {
            setListedCustomer(res);
          }, 500);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      ErrorToast("Please enter valid mobile number!");
    }
  };

  useEffect(() => {
    props.onTrigger(process.env.REACT_APP_GET_POST_CUSTOMER);
    setEmployees(
      getDropdown("employee", sharedData).filter(
        (emp) => emp.key != employeeInfo.ecno
      )
    );
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const _shareddata: any = JSON.parse(
        JSON.parse(JSON.stringify(secureStorage.getItem("shareddata")))
      );
      const _empIfo = JSON.parse(
        JSON.parse(JSON.stringify(secureStorage.getItem("empinfo")))
      );
      setCustomerDetails({
        ...CustomerDetails,
        ["employeeid"]: _empIfo.ecno,
        ["branch"]: _empIfo.branchid,
      });

      setEmployeeInfo(_empIfo);
      setSchemes(
        getDropdown("scheme", _shareddata).filter((s: any) => {
          return s.text.split("-")[1] > 0;
        })
      );
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (state && !props.saved) {
      let _data: CustomerDetail = JSON.parse(state?.data);
      console.log(state.isedit);

      if (!state.isedit) {
        _data.customerid = _data.id;
        _data.aadharnumber = _data?.aadharnumber
          ? _data.aadharnumber
          : 123456789098;
        _data.employeeid = employeeInfo.ecno;
        _data.branch = employeeInfo.branchid;
        _data.insert_update = 1;
        _data.schemeid = _data?.schemeid ? _data.schemeid : "";
        _data.cardnumber = _data.mobileNo;
        _data.startdate = firstDays.toISOString().split("T")[0];
        _data.enddate = lastDay.toISOString().split("T")[0];
        _data.joiningecno = "";
      } else {
        setTimeout(() => {
          getCustomerSchemes(
            String(process.env.REACT_APP_GET_CUSTOMER_SCHEMES),
            Number(_data.customerid)
          );
        }, 500);
        // _data.groupserialnumber
      }

      setTimeout(() => {
        setIsModalOpen(false);
        setCustomerDetails(_data);
        props.onTrigger(
          state?.isedit
            ? process.env.REACT_APP_GET_PUT_CUSTOMER
            : process.env.REACT_APP_GET_POST_CUSTOMER
        );
      }, 1500);
    }
  }, [state?.data]);

  const getCustomerSchemes = (url: any, id: number): void => {
    HttpKnexGet(url, id).then((res: any) => {
      setTimeout(() => {
        if (res.length > 0) {
          /* Show grouped schemes - COnfirm with Nataraj
          const _schemeGrp: CustomerSchemes[] = [];
          res.forEach((element: CustomerSchemes) => {
            if (_schemeGrp.length === 0) {
              _schemeGrp.push(element);
              return;
            } else {
              _schemeGrp.map((el: CustomerSchemes) => {
                if (el.schemeid != element.schemeid) {
                  _schemeGrp.push(element);
                }
              });
            }
          });
          */
          setCustomerSchemes(res);
        } else {
          setCustomerSchemes([]);
        }
      }, 500);
    });
  };

  useEffect(() => {
    props.onProcessdata(CustomerDetails);
  });

  useEffect(() => {
    props.onReset(false);
    props.onTrigger(process.env.REACT_APP_GET_POST_CUSTOMER);
    window.history.replaceState({}, "");
    state = null;
    setCustomerDetails((prev) => CustomerInitialState);
    setListedCustomer([]);
    setCustomerSchemes([]);
    setTimeout(() => {
      setCustomerDetails({
        ...CustomerDetails,
        ["employeeid"]: employeeInfo.ecno,
        ["branch"]: employeeInfo.branchid,
      });
    }, 500);
  }, [props.reset]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (CustomerDetails?.mobileNo?.length === 10) handleEnterKeyPress();
      else ErrorToast("Please enter valid mobile number!");
    }
  };

  const handleEnterKeyPress = () => {
    populateCustomer();
  };

  const selectScheme = (data: CustomerSchemes) => {
    setCustomerDetails({
      ...CustomerDetails,
      ["schemeid"]: data.schemeid,
      ["schemeamount"]: data.schemeamount,
      ["groupserialnumber"]: data.groupnumber,
      ["groupname"]: data.groupname,
    });
  };

  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <Label as="a" color="red" ribbon>
            Customer Info
          </Label>
          <FormGroup widths="equal">
            <FormInput
              fluid={true}
              type="number"
              value={CustomerDetails?.mobileNo}
              label="Mobile Number"
              error={CustomerDetails?.mobileNo == "" ? props.isValid : false}
              placeholder="Mobile Number"
              id="mobileNo"
              name="mobileNo"
              onChange={handleChange}
              onInput={maxLengthCheck}
              onKeyDown={handleKeyDown}
              maxLength="10"
              icon={
                <Icon
                  name="search"
                  inverted
                  circular
                  link
                  onClick={populateCustomer}
                />
              }
            />

            <FormInput
              type="number"
              fluid={true}
              label="Card Number"
              key="cuscardnumber"
              value={CustomerDetails?.mobileNo}
              placeholder="Card Number"
              id="cardnumber"
              name="cardnumber"
              style={{ opacity: "0.6", pointerEvents: "none" }}
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Customer Name"
              value={CustomerDetails?.customerName}
              placeholder="Customer Name"
              id="customerName"
              name="customerName"
              error={
                CustomerDetails?.customerName == "" ? props.isValid : false
              }
              onChange={handleChange}
              maxLength="150"
            />
            <FormInput
              type="text"
              id="relationship"
              name="relationship"
              placeholder="Relationship"
              label="Customer Relationship"
              fluid={true}
              maxLength={100}
              value={CustomerDetails?.relationship}
              onChange={handleChange}
              error={
                CustomerDetails?.relationship == "" ? props.isValid : false
              }
            />
          </FormGroup>

          <FormGroup roup widths="equal">
            <FormInput
              fluid={true}
              type="text"
              value={CustomerDetails?.doorNo}
              label="Door Number"
              error={CustomerDetails?.doorNo == "" ? props.isValid : false}
              placeholder="Door Number"
              id="doorNo"
              name="doorNo"
              style={{ opacity: "0.6" }}
              readOnly={true}
              onInput={maxLengthCheck}
              maxLength="20"
            />
            <FormInput
              fluid={true}
              type="text"
              value={CustomerDetails?.street}
              label="Street"
              error={CustomerDetails?.street == "" ? props.isValid : false}
              placeholder="Street"
              id="street"
              name="street"
              style={{ opacity: "0.6" }}
              readOnly={true}
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormField>
              <FormInput
                type="number"
                fluid={true}
                label="Aadhar Number"
                value={CustomerDetails?.aadharnumber}
                placeholder="Aadhar Number"
                id="aadharnumber"
                name="aadharnumber"
                error={
                  CustomerDetails?.aadharnumber == "" ? props.isValid : false
                }
                style={{ opacity: "0.6" }}
                readOnly={true}
                onInput={maxLengthCheck}
                maxLength="12"
              />

              {CustomerDetails?.aadharnumber != "" &&
              props.isFormatted.includes("AADHAR") ? (
                <Label basic color="red" pointing="above">
                  Please enter valid AADHAR number
                </Label>
              ) : (
                ""
              )}
            </FormField>
            <FormInput
              fluid={true}
              type="number"
              value={CustomerDetails?.pinCode}
              label="Pincode"
              error={CustomerDetails?.pinCode == "" ? props.isValid : false}
              placeholder="Pincode"
              id="pinCode"
              name="pinCode"
              style={{ opacity: "0.6" }}
              readOnly={true}
              onInput={maxLengthCheck}
              maxLength="6"
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="text"
              fluid={true}
              label="Area"
              value={CustomerDetails?.area}
              placeholder="Area"
              id="area"
              name="area"
              error={CustomerDetails?.area == "" ? props.isValid : false}
              style={{ opacity: "0.6" }}
              readOnly={true}
              maxLength="150"
            />

            <FormInput
              type="text"
              fluid={true}
              label="Taluk"
              value={CustomerDetails?.taluk}
              placeholder="Taluk"
              id="taluk"
              name="taluk"
              error={CustomerDetails?.taluk == "" ? props.isValid : false}
              style={{ opacity: "0.6" }}
              readOnly={true}
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths={"equal"}>
            <FormInput
              type="text"
              fluid={true}
              label="City"
              value={CustomerDetails?.city}
              placeholder="City"
              id="city"
              name="city"
              error={CustomerDetails?.city == "" ? props.isValid : false}
              style={{ opacity: "0.6" }}
              readOnly={true}
              maxLength="150"
            />

            <FormInput
              type="text"
              fluid={true}
              label="State"
              value={CustomerDetails?.state}
              placeholder="State"
              id="state"
              name="state"
              error={CustomerDetails?.state == "" ? props.isValid : false}
              style={{ opacity: "0.6" }}
              readOnly={true}
              maxLength="150"
            />
          </FormGroup>
          <FormGroup widths={"equal"}>
            <FormField>
              <FormInput
                type="email"
                fluid={true}
                label="Email ID"
                value={CustomerDetails?.email}
                placeholder="Email ID"
                id="email"
                name="email"
                error={CustomerDetails?.email == "" ? props.isValid : false}
                style={{ opacity: "0.6" }}
                readOnly={true}
                maxLength="200"
              />
              {CustomerDetails?.email != "" &&
              props.isFormatted.includes("email") ? (
                <Label basic color="red" pointing="above">
                  Please enter valid email
                </Label>
              ) : (
                ""
              )}
            </FormField>
            <FormSelect
              type="select"
              fluid={true}
              label="Employee ID"
              value={CustomerDetails?.joiningecno}
              placeholder="Type or select employee name"
              id="joiningecno"
              name="joiningecno"
              error={CustomerDetails?.joiningecno == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
              options={employess}
              search
              scrolling
              disabled={CustomerDetails?.insert_update > 1 ? true : false}
            />

            {/* <FormInput
              type="text"
              fluid={true}
              label="Employee ID"
              value={CustomerDetails?.employeeid}
              placeholder="Employee ID"
              id="employeeid"
              name="employeeid"
              error={CustomerDetails?.employeeid == "" ? props.isValid : false}
              style={{ opacity: "0.6" }}
              readOnly={true}
            /> */}
          </FormGroup>
          <Label as="a" color="red" ribbon>
            Scheme Info
          </Label>
          {customerSchemes.length > 0 ? (
            <Popup
              trigger={
                <Button size="mini" basic color="red">
                  Show schems
                </Button>
              }
              flowing
              hoverable
            >
              <Grid centered divided columns={3}>
                {(customerSchemes || []).map((item, idx) => {
                  return (
                    <GridColumn textAlign="center" id={idx}>
                      <Header as="h4">{item.schemename}</Header>
                      <Header as="h3">{item.groupname}</Header>
                      <p>
                        <b>Scheme Amount</b> {item.schemeamount}
                      </p>
                      <p>
                        <b>Group #</b> {item.groupnumber}
                      </p>
                      <Button
                        color="blue"
                        size="tiny"
                        inverted
                        onClick={() => {
                          selectScheme(item);
                        }}
                      >
                        Select
                      </Button>
                    </GridColumn>
                  );
                })}
              </Grid>
            </Popup>
          ) : (
            ""
          )}
          <FormGroup widths="equal">
            <FormSelect
              type="select"
              fluid={true}
              label="Scheme"
              value={CustomerDetails?.schemeid}
              placeholder="Type or select scheme name"
              id="schemename"
              name="schemename"
              error={CustomerDetails?.schemeid == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
              options={schemes}
              search
              scrolling
              disabled={CustomerDetails?.insert_update > 1 ? true : false}
            />

            <FormInput
              type="number"
              fluid={true}
              label="Scheme Amount"
              value={CustomerDetails?.schemeamount}
              placeholder="Scheme Amount"
              id="schemeamount"
              name="schemeamount"
              maxLength="50"
              style={{ opacity: "0.6" }}
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              type="number"
              fluid={true}
              label="Group Serial Number"
              value={CustomerDetails?.groupserialnumber}
              placeholder="Serial Number"
              id="groupserialnumber"
              name="groupserialnumber"
              maxLength="150"
              style={{ opacity: "0.6" }}
            />

            <FormInput
              type="text"
              fluid={true}
              label="Group Name"
              value={CustomerDetails?.groupname}
              placeholder="Group Name"
              id="groupname"
              name="groupname"
              maxLength="50"
              style={{ opacity: "0.6" }}
            />
          </FormGroup>
          <Label as="a" color="red" ribbon>
            Nominee Info
          </Label>
          <FormGroup widths={"equal"}>
            <FormInput
              type="text"
              fluid={true}
              label="Name"
              value={CustomerDetails?.nomineeName}
              placeholder="Name"
              id="nomineeName"
              name="nomineeName"
              error={CustomerDetails?.nomineeName == "" ? props.isValid : false}
              onChange={handleChange}
              maxLength="150"
            />
            <FormInput
              type="text"
              id="nomineeRelation"
              name="nomineeRelation"
              placeholder="Relationship"
              label="Relationship"
              fluid={true}
              maxLength={100}
              value={CustomerDetails?.nomineeRelation}
              onChange={handleChange}
              error={
                CustomerDetails?.nomineeRelation == "" ? props.isValid : false
              }
            />
          </FormGroup>
          <FormInput
            type="number"
            fluid={true}
            label="Mobile Number"
            value={CustomerDetails?.nomineeMobile}
            placeholder="Mobile Number"
            id="nomineeMobile"
            name="nomineeMobile"
            error={CustomerDetails?.nomineeMobile == "" ? props.isValid : false}
            onChange={handleChange}
            onInput={maxLengthCheck}
            maxLength="10"
          />
          <Label as="a" color="red" ribbon>
            Photos
          </Label>
          {/* <FormGroup widths="equal"> */}
          <Grid className="csgrid">
            <GridRow columns={2} centered>
              <GridColumn>
                <Image
                  width="450px"
                  height="400px"
                  src={
                    CustomerDetails?.photo1
                      ? CustomerDetails?.photo1
                      : process.env.REACT_APP_DUMMY_IMAGE
                  }
                />
              </GridColumn>
              <GridColumn>
                <Image
                  width="450px"
                  height="400px"
                  src={
                    CustomerDetails?.photo2
                      ? CustomerDetails?.photo2
                      : process.env.REACT_APP_DUMMY_IMAGE
                  }
                />
              </GridColumn>
            </GridRow>
          </Grid>
          <props.button {...props} />
          <ToastContainer />
        </Form>
      </Container>
      <ModelTable
        isOpen={isModalOpen}
        data={listedCustomer || []}
        isselect={isSelect}
        isedit={isSelect}
      />
    </div>
  );
}
export default CustomerDetails;
