import React, { useState, useEffect, useContext, useRef } from "react";
import { Button, Icon } from "semantic-ui-react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import Buttons from "../components/Buttons.tsx";
import { HttpGet, HttpTrigger, useFetch } from "../lib/useFetch.tsx";
import Customspinner from "../components/Customspinner.tsx";
import { Result, useGlobalContext } from "../lib/types.ts";
import { SuccessToast, ErrorToast } from "../utils/ProjectToast.js";
import { useNavigate, useLocation } from "react-router-dom";
import ReactTopProgressBar from "react-top-progress-bar";
import { Utility } from "../utils/Utility.js";
import {
  Validate,
  ValidateChitGift,
  ValidateConcern,
  ValidateConfigMenus,
  ValidateCustomer,
  Validatescheme,
  ValidateUserRoles,
} from "../lib/formvalidation.ts";
import {
  Dropdowns,
  EmployeDetails,
  EmployeeConfigMenus,
  GoldRates,
} from "../lib/dbobjectmodel.ts";
import { EmployeInitialState } from "../lib/initialState.ts";
import ChitCard from "../components/chitcard.tsx";
import SecureStorage from "../utils/storageservice.tsx";

function Master({ component: Component, props: Props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const contentRef = useRef(null);
  const secureStorage = new SecureStorage(sessionStorage);
  const { setEmployeeInfo, setGoldRate } = useContext(useGlobalContext);

  const [inputData, setData] = useState<any>();
  const [isReset, setIsReset] = useState<Boolean>(false);
  const [trigger, setTrigger] = useState<string>("");
  const [spinners, setspinners] = useState<boolean>(false);
  const [isDataSaving, setIsDataSaving] = useState<boolean>(false);
  const [isValid, setisValid] = useState(false);
  const [isFormatted, setFormatted] = useState<string>("");
  const { setSharedData, employeeInfo, setMenuAccess, menuaccess } =
    useContext(useGlobalContext);
  const [employee, setEmployee] = useState<EmployeDetails>(EmployeInitialState);
  const [openCustomerChitCard, setOpenCustomerChitCard] =
    useState<boolean>(false);
  const [customerInfo, setCustomerInfo] = useState<any>([]);
  const [access, setAccess] = useState<EmployeeConfigMenus>(
    Utility.superadmin.access
  );
  const [progCount, setProgCount] = useState(0);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const { status, statusText, data, error, loading } = useFetch<Dropdowns[]>(
    process.env.REACT_APP_DROPDOWNS,
    [],
    undefined
  );

  useEffect(() => {
    if (location.pathname.includes("customerschemeclose")) {
      return;
    } else {
      if (employeeInfo == null || Object.keys(employeeInfo).length === 0) {
        const _empIfo = JSON.parse(
          JSON.parse(JSON.stringify(secureStorage.getItem("empinfo")))
        );
        const _goldRate: any = JSON.parse(
          JSON.parse(JSON.stringify(secureStorage.getItem("goldrate")))
        );
        const _shareddata: any = JSON.parse(
          JSON.parse(JSON.stringify(secureStorage.getItem("shareddata")))
        );
        const _menuaccess: any = JSON.parse(
          JSON.parse(JSON.stringify(secureStorage.getItem("menuaccess")))
        );

        if (_empIfo) {
          setTimeout(() => {
            setEmployeeInfo(_empIfo);
            setGoldRate(_goldRate);
            setSharedData(_shareddata);
            setMenuAccess(_menuaccess);
          }, 100);
        } else {
          ErrorToast("Session expired, please login again!");
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    setOpenCustomerChitCard(false);
    if (
      employeeInfo.ecno === Utility.superadmin.username ||
      employeeInfo.ecno === Utility.admin.username
    ) {
      setTimeout(() => {
        setAccess(menuaccess);
        setMenuAccess(menuaccess);
      }, 500);
    }
    setIsSaved(false);
  }, []);

  useEffect(() => {
    setProgCount(0);
    const interval = setInterval(() => {
      setProgCount((prev) => {
        if (prev >= 100) {
          setProgCount(0);
          clearInterval(interval);
          return 100;
        }
        return prev + 10;
      });
    }, 1000);

    setAccess(undefined);

    const _url = location.pathname.split("/")[2];

    setTimeout(() => {
      let menu: string = "";
      if (_url.includes("view")) {
        if (_url.includes("schemes")) {
          menu = "schemes";
        } else if (_url.includes("customers")) {
          menu = "customerdetails";
        } else if (_url.includes("chitgift")) {
          menu = "chitgift";
        } else if (_url.includes("concerns")) {
          menu = "concerns";
        }
      } else {
        menu = _url;
      }

      if (
        employeeInfo.ecno !== Utility.superadmin.username &&
        employeeInfo.ecno !== Utility.admin.username
      ) {
        let _queryParam = `${employeeInfo.department}&menu=${menu}`;
        HttpGet(process.env.REACT_APP_ACCESS_CONFIG_MENUS, _queryParam).then(
          (m: EmployeeConfigMenus) => {
            if (m) {
              m.all = m.all == 0 ? false : true;
              m.view = m.view == 0 ? false : true;
              m.add = m.add == 0 ? false : true;
              m.edit = m.edit == 0 ? false : true;
              m.delete = m.delete == 0 ? false : true;

              setTimeout(() => {
                setAccess(m);
                setMenuAccess(m);
                secureStorage.setItem("menuaccess", JSON.stringify(m));
              }, 500);
            }
          }
        );
      } else {
        // setTimeout(() => {
        setAccess(menuaccess);
        setMenuAccess(menuaccess);
        secureStorage.setItem("menuaccess", JSON.stringify(menuaccess));
        // }, 500);
      }
    }, 1000);
  }, [location.pathname]);

  useEffect(() => {
    setSharedData(data);
    secureStorage.setItem("shareddata", JSON.stringify(data));
    setEmployee(employeeInfo);
  }, [status == 200]);

  const handleProcessData = (inputData: any) => {
    setData(inputData);
  };
  const onTrigger = (trigger: string) => {
    setTrigger(trigger);
  };

  const handlePrint = useReactToPrint({
    contentRef: contentRef,
    onAfterPrint: () => {
      contentRef.current = null;
      setOpenCustomerChitCard(false);
    },
  });

  const handleReset = () => {
    setIsReset(true);
    setTimeout(() => {
      setIsSaved(false);
    }, 1000);
  };

  const onResetState = (flag: boolean) => {
    setIsReset(flag);
    setisValid(false);
    setFormatted("");
    setIsSaved(false);
  };

  const handleSave = () => {
    let _FormValidation: any;
    let mesage: string = "";
    if (location.pathname.includes("schemes")) {
      _FormValidation = Validatescheme;
    } else if (location.pathname.includes("customerdetails")) {
      setCustomerInfo([]);
      setCustomerInfo((customerInfo) => [...customerInfo, inputData?.mobileNo]);
      setCustomerInfo((customerInfo) => [
        ...customerInfo,
        inputData?.customerName,
      ]);
      setCustomerInfo((customerInfo) => [...customerInfo, inputData?.city]);
      setCustomerInfo((customerInfo) => [
        ...customerInfo,
        inputData?.groupserialnumber,
      ]);
      _FormValidation = ValidateCustomer;
    } else if (location.pathname.includes("concerns")) {
      _FormValidation = ValidateConcern;
    } else if (location.pathname.includes("concernroles")) {
      _FormValidation = ValidateUserRoles;
    } else if (location.pathname.includes("chitgift")) {
      _FormValidation = ValidateChitGift;
    } else if (location.pathname.includes("configmenus")) {
      _FormValidation = ValidateConfigMenus;
    }

    if (Validate(_FormValidation, inputData)) {
      setisValid(true);
      ErrorToast("Please fill all mandatory fields");
      return;
    } else if (location.pathname.includes("customerdetails")) {
      if (inputData?.aadharnumber?.length < 12) {
        mesage += "Please enter valid AADHAR number! \n";
      }
      if (inputData?.pinCode.length < 6) {
        mesage += "Please enter valid pincode! \n";
      }
      if (inputData?.mobileNo.length < 10) {
        mesage += "Please enter valid mobile number! \n";
      }
      if (!Utility.validateEmail(inputData?.email)) {
        mesage += "Please enter valid email! \n";
      }
      if (inputData?.nomineeMobile.length < 10) {
        mesage += "Please enter valid nominee mobile number! \n";
      }
    } else if (location.pathname.includes("addconcern")) {
      if (inputData?.pincode.length < 6) {
        mesage += "Please enter valid pincode! \n";
      }
      if (inputData?.mobilenumber.length < 10) {
        mesage += "Please enter valid mobile number! \n";
      }
      if (inputData?.landlinenumber.length < 10) {
        mesage += "Please enter valid Landline number! \n";
      }
      if (
        inputData?.gstn.length < 15 &&
        !Utility.validateGSTIN(inputData?.gstn)
      ) {
        mesage += "Please enter valid GSTN number! \n";
      }

      if (inputData?.pan.length < 10 && !Utility.validatepan(inputData?.pan)) {
        mesage += "Please enter valid PAN number! \n";
      }
      if (inputData?.tan != "" && inputData?.tan.length < 10) {
        mesage += "Please enter valid TAN number! \n";
      }
      if (
        inputData?.cin != "" &&
        inputData?.cin.length < 21 &&
        !Utility.validatecin(inputData?.cin)
      ) {
        mesage += "Please enter valid CIN number! \n";
      }
    }
    if (mesage.length > 6) {
      console.log(mesage);

      ErrorToast(mesage);
      setFormatted(mesage);
      return;
    } else {
      inputData.CreatedBy = employee.ecno;
      inputData.ModifiedBy = employee.ecno;
      inputData.deletedBy = employee.ecno;

      setisValid(false);
      setspinners(true);
      HttpTrigger(
        trigger,
        inputData?.insert_update == 1 ? "POST" : "PUT",
        inputData
      )
        .then((res: Result) => {
          // setspinners(false);
          setTimeout(() => {
            setspinners(false);
            if (location.pathname.includes("customerdetails")) {
              setOpenCustomerChitCard(true);
            }
          }, 1000);
          if (res.message === "success") {
            SuccessToast("Details saved successfully!");
            setIsSaved(true);
            HttpGet(process.env.REACT_APP_DROPDOWNS, undefined).then(
              (res: any) => {
                setTimeout(() => {
                  const data: Dropdowns[] = res;
                  secureStorage.removeItem("shareddata");
                  secureStorage.setItem("shareddata", JSON.stringify(data));
                  setSharedData(data);
                }, 500);
              }
            );
            if (inputData?.insert_update > 1) {
              setTimeout(() => {
                navigate(-1);
              }, 3500);
            }
          } else {
            ErrorToast(
              "Something went wrong, please try again after sometime!"
            );
          }
          setFormatted("");
        })
        .catch((error) => {
          setspinners(false);
          ErrorToast("Something went wrong, please try again after sometime!");
        });
    }
  };

  const toggle = () => {
    setOpenCustomerChitCard(false);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setOpenCustomerChitCard(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      {!spinners ? (
        <>
          <ReactTopProgressBar color="#00ff00" value={progCount} />
          <Component
            {...Props}
            onProcessdata={handleProcessData}
            onReset={onResetState}
            onTrigger={onTrigger}
            handleResetClick={handleReset}
            handleSaveClick={handleSave}
            button={Buttons}
            reset={isReset}
            isDataSaving={isDataSaving}
            isValid={isValid}
            isFormatted={isFormatted}
            access={access}
            saved={isSaved}
          />
        </>
      ) : (
        <Customspinner text="Saving..."></Customspinner>
      )}
      <Modal isOpen={openCustomerChitCard} backdrop={true}>
        <ModalHeader>
          <div>
            <h5>Print Chit Card</h5>
          </div>
          <div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
        </ModalHeader>
        <ModalBody>
          <ChitCard
            mobilenumber={customerInfo[0]}
            customername={customerInfo[1]}
            city={customerInfo[2]}
            groupno={customerInfo[3]}
            innerRef={contentRef}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            icon
            color="linkedin"
            size="big"
            labelPosition="left"
            type="button"
            onClick={handlePrint}
          >
            Print
            <Icon name="print" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Master;
