import React, { useEffect, useState } from "react";
import CustomTable from "../../components/Table/table.tsx";
import { TableHeaders } from "../../components/Table/tableheader.js";
import Customspinner from "../../components/Customspinner.tsx";
import { CustomerDetail } from "../../lib/dbobjectmodel";
import { CustomerInitialState } from "../../lib/initialState.ts";
import { useFetch } from "../../lib/useFetch.tsx";
function ViewCustomer() {
  const [customers, setCustomers] = useState<CustomerDetail[]>();

  const { status, statusText, data, error, loading } = useFetch<
    CustomerDetail[]
  >(process.env.REACT_APP_GET_URL_VIEW_ALL_CUSTOMER, [], "");

  useEffect(() => {
    setCustomers(data);
  }, [status == 200]);
  return (
    <>
      {!loading ? (
        <div className="content">
          <CustomTable
            datas={customers || []}
            headers={TableHeaders.viewCustomers}
            isedit={true}
            isdelete={true}
            iswhatapp={false}
            issms={false}
            iscustomer={false}
            isdefault={false}
            isapprove={false}
            isfilter={true}
            isphoto={true}
            navigateurl="/admin/customerdetails"
            deleteurl={process.env.REACT_APP_DELETE_CUSTOMER}
          />
        </div>
      ) : loading ? (
        <Customspinner text="Loading.." />
      ) : (
        ""
      )}
    </>
  );
}

export default ViewCustomer;
