import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes, useLocation } from "react-router-dom";

import DemoNavbar from "../components/Navbars/DemoNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";

import { routes, viewroutes } from "../routes.js";
import Master from "./master.tsx";
var ps;

function AdminLayout(props) {
  const [backgroundColor, setBackgroundColor] = React.useState("#2c662c");
  const [activeColor, setActiveColor] = React.useState("info");
  const mainPanel = React.useRef();
  const location = useLocation();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);
  // const handleActiveClick = (color) => {
  //   setActiveColor(color);
  // };
  // const handleBgClick = (color) => {
  //   setBackgroundColor(color);
  // };
  return (
    <div className="wrapper">
      {location.pathname.includes("customerschemeclose") ? (
        ""
      ) : (
        <Sidebar
          {...props}
          routes={routes}
          viewroutes={viewroutes}
          activeColor={activeColor}
        />
      )}
      <div className="main-panel" ref={mainPanel}>
        {location.pathname.includes("customerschemeclose") ? (
          ""
        ) : (
          <DemoNavbar {...props} />
        )}

        <Routes>
          {routes.map((prop, key) => {
            return (
              <>
                <Route
                  path={prop.path}
                  element={
                    <Master
                      {...props}
                      component={prop.component}
                      type={prop.type}
                    />
                  }
                  key={key}
                  exact
                ></Route>
              </>
            );
          })}
          {viewroutes.map((prop, key) => {
            return (
              <>
                <Route
                  path={prop.path}
                  element={<Master {...props} component={prop.component} />}
                  key={key}
                  exact
                />
              </>
            );
          })}
        </Routes>
      </div>
    </div>
  );
}

export default AdminLayout;
