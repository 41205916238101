var CryptoJS = require("crypto-js");

const SECRET_KEY: any =
  "chennai silks chit management syatem created on 2024 by hadot software";

class SecureStorage {
  storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  hash(key: any): string {
    const hashedKey = CryptoJS.SHA256(key, SECRET_KEY).toString();
    return hashedKey;
  }

  encrypt(data: any): string {
    const encryptedData = CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
    return encryptedData;
  }

  decrypt(data: any): string {
    const decryptedData = CryptoJS.AES.decrypt(data, SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return decryptedData;
  }

  setItem(key: string, value: string) {
    const hashedKey = this.hash(key);
    const encryptedValue = this.encrypt(value);
    this.storage.setItem(hashedKey, encryptedValue);
  }

  removeItem(key: string) {
    const hashedKey = this.hash(key);
    this.storage.removeItem(hashedKey);
  }

  getItem(key: string): string | null {
    const hashedKey = this.hash(key);
    const encryptedValue = this.storage.getItem(hashedKey);
    if (encryptedValue) {
      return this.decrypt(encryptedValue);
    }
    return null;
  }
}

export default SecureStorage;
