import React, { createContext, ReactNode, useState } from "react";
import { useGlobalContext } from "./types.ts";
import {
  Dropdowns,
  EmployeDetails,
  EmployeeConfigMenus,
  GoldRates,
} from "./dbobjectmodel";

type MyProviderProps = {
  children: ReactNode; // ReactNode represents any valid React child (elements, strings, etc.)
};

export const MyProvider: React.FC<MyProviderProps> = ({ children }) => {
  const [sharedData, setData] = useState<Dropdowns[]>([]);
  const [employeeInfo, setEmployee] = useState<EmployeDetails>({});
  const [menuaccess, setMenu] = useState<EmployeeConfigMenus>({});
  const [goldRate, setGold] = useState<GoldRates>({});

  const setSharedData = (newData: Dropdowns[]) => {
    setData(newData);
  };

  const setEmployeeInfo = (newData: EmployeDetails) => {
    setEmployee(newData);
  };

  const setMenuAccess = (newData: EmployeeConfigMenus) => {
    setMenu(newData);
  };
  const setGoldRate = (newData: GoldRates) => {
    setGold(newData);
  };

  return (
    <useGlobalContext.Provider
      value={{
        sharedData,
        setSharedData,
        employeeInfo,
        setEmployeeInfo,
        menuaccess,
        setMenuAccess,
        goldRate,
        setGoldRate,
      }}
    >
      {children}
    </useGlobalContext.Provider>
  );
};
