import React, { useContext, useEffect, useState } from "react";

import {
  FormInput,
  FormGroup,
  Form,
  Button,
  FormField,
  Container,
  Checkbox,
  TextArea,
  Icon,
  FormSelect,
  Message,
  Label,
  Placeholder,
  PlaceholderImage,
  ItemGroup,
  Item,
  ItemImage,
  Grid,
  GridRow,
  GridColumn,
  Image,
  Popup,
  Header,
} from "semantic-ui-react";
import {
  CustomerSchemes,
  PaymentHistory,
  type CustomerDetail,
} from "../lib/dbobjectmodel.ts";
import { useLocation } from "react-router-dom";
import { CustomerInitialState } from "../lib/initialState.ts";
import {
  ErrorToast,
  maxLengthCheck,
  SuccessToast,
} from "../utils/ProjectToast.js";
import { ToastContainer } from "react-toastify";
import {
  HttpGet,
  HttpKnexGet,
  HttpLoginTrigger,
  HttpSKTMTrigger,
} from "../lib/useFetch.tsx";
import Customspinner from "../components/Customspinner.tsx";
import { useGlobalContext } from "../lib/types.ts";

function CustomerSchemeClose(props: any) {
  const [CustomerDetails, setCustomerDetails] =
    useState<CustomerDetail>(CustomerInitialState);
  const [loading, setLoading] = useState<boolean>(true);
  const [approved, setApproved] = useState<boolean>(false);
  const { goldRate } = useContext(useGlobalContext);
  const [installmentWeight, setInstallmentWeight] = useState<any>(0);
  const [totalSchemeAmount, setTotalSchemeAmount] = useState<any>(0);

  const query = new URLSearchParams(useLocation().search);
  const mobileNo = query.get("mobileno");
  const key = query.get("key");
  const id = query.get("schemeid");
  let GoldRate: number = 0;

  const populateCustomer = (data: any) => {
    HttpGet(`${process.env.REACT_APP_GET_CUSTOMER_SCHEME_CLOSE_DETAILS}`, data)
      .then((res: any) => {
        if (res?.availableschemes.length > 0) {
          const result = res.availableschemes[0];
          let totalAmount = 0;
          HttpKnexGet(
            String(process.env.REACT_APP_GET_CUSTOMER_PAYMENT_HISTORY),
            `${res.customerid}~${result.schemeid}~${result.groupnumber}`
          ).then((history) => {
            setTimeout(() => {
              if (history.length > 0) {
                history.map((itm) => {
                  totalAmount +=
                    Number(history.cashamount) +
                    Number(history.bankamount) +
                    Number(history.cardamount);
                });
                setTotalSchemeAmount(totalAmount);
              }
            }, 500);
          });

          setCustomerDetails(res);

          setTimeout(() => {
            switch (result.weight) {
              case "1":
                setTimeout(() => {
                  const _installmentWeight = (
                    result?.schemeamount
                      ? (result.schemeamount *
                          (result.recievedinstallment
                            ? result.recievedinstallment
                            : 1)) /
                        GoldRate
                      : 0
                  ).toFixed(2);
                  setInstallmentWeight(_installmentWeight);
                }, 1000);
                break;
              case "2":
                setTimeout(() => {
                  setInstallmentWeight(
                    Number(
                      result?.schemeamount ? result.schemeamount * GoldRate : 0
                    )
                  );
                }, 1000);
                break;
              default:
                setTimeout(() => {
                  setInstallmentWeight(0);
                }, 1000);
                break;
            }
          }, 500);
        } else {
          SuccessToast(
            "You have already redeemed the scheme or not a valid scheme!"
          );
          setApproved(true);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    HttpSKTMTrigger(`${process.env.REACT_APP_SKTM_GOLDRATE}`, null).then(
      (res: any) => {
        GoldRate = Number(res[0].goldRate);
        populateCustomer(id);
      }
    );
  }, []);

  const onSubmitApprove = () => {
    const _data = {
      id: id,
      key: key,
      mobileno: mobileNo,
    };
    HttpLoginTrigger(
      String(process.env.REACT_APP_REDEEM_APPROVE),
      "POST",
      _data
    )
      .then((res: any) => {
        if (res.data.length > 0) {
          SuccessToast("You are redeem was successful!");
          setTimeout(() => {
            setLoading(false);
            setApproved(true);
          }, 2000);
        } else {
          ErrorToast("You are already redeemed this scheme!");
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
      })
      .catch((error) => {});
  };

  return (
    <div className="content">
      {loading ? (
        <Customspinner />
      ) : (
        <Container className="project-form">
          <Form>
            <FormGroup widths="equal">
              <FormInput
                fluid={true}
                type="number"
                value={CustomerDetails?.mobileNo}
                label="Mobile Number"
                style={{ opacity: "0.6" }}
                readOnly={true}
              />

              <FormInput
                type="number"
                fluid={true}
                label="Card Number"
                key="cuscardnumber"
                id="cardnumber"
                name="cardnumber"
                value={CustomerDetails?.mobileNo}
                style={{ opacity: "0.6" }}
                readOnly={true}
              />

              <FormInput
                type="text"
                fluid={true}
                label="Customer Name"
                value={CustomerDetails?.customerName}
                placeholder="Customer Name"
                id="customerName"
                name="customerName"
                style={{ opacity: "0.6" }}
                readOnly={true}
              />
              <FormInput
                type="text"
                id="relationship"
                name="relationship"
                placeholder="Relationship"
                label="Customer Relationship"
                fluid={true}
                maxLength={100}
                value={CustomerDetails?.relationship}
                style={{ opacity: "0.6" }}
                readOnly={true}
              />
            </FormGroup>

            <FormGroup roup widths="equal">
              <FormInput
                fluid={true}
                type="text"
                value={CustomerDetails?.doorNo}
                label="Door Number"
                error={CustomerDetails?.doorNo == "" ? props.isValid : false}
                placeholder="Door Number"
                id="doorNo"
                name="doorNo"
                style={{ opacity: "0.6" }}
                readOnly={true}
              />
              <FormInput
                fluid={true}
                type="text"
                value={CustomerDetails?.street}
                label="Street"
                error={CustomerDetails?.street == "" ? props.isValid : false}
                placeholder="Street"
                id="street"
                name="street"
                style={{ opacity: "0.6" }}
                readOnly={true}
              />

              <FormField>
                <FormInput
                  type="number"
                  fluid={true}
                  label="Aadhar Number"
                  value={CustomerDetails?.aadharnumber}
                  placeholder="Aadhar Number"
                  id="aadharnumber"
                  name="aadharnumber"
                  error={
                    CustomerDetails?.aadharnumber == "" ? props.isValid : false
                  }
                  style={{ opacity: "0.6" }}
                  readOnly={true}
                  onInput={maxLengthCheck}
                  maxLength="12"
                />

                {CustomerDetails?.aadharnumber != "" &&
                props.isFormatted.includes("AADHAR") ? (
                  <Label basic color="red" pointing="above">
                    Please enter valid AADHAR number
                  </Label>
                ) : (
                  ""
                )}
              </FormField>
              <FormInput
                fluid={true}
                type="number"
                value={CustomerDetails?.pinCode}
                label="Pincode"
                error={CustomerDetails?.pinCode == "" ? props.isValid : false}
                placeholder="Pincode"
                id="pinCode"
                name="pinCode"
                style={{ opacity: "0.6" }}
                readOnly={true}
                onInput={maxLengthCheck}
                maxLength="6"
              />
            </FormGroup>
            <FormGroup widths="equal">
              <FormInput
                type="text"
                fluid={true}
                label="Area"
                value={CustomerDetails?.area}
                placeholder="Area"
                id="area"
                name="area"
                error={CustomerDetails?.area == "" ? props.isValid : false}
                style={{ opacity: "0.6" }}
                readOnly={true}
                maxLength="150"
              />

              <FormInput
                type="text"
                fluid={true}
                label="Taluk"
                value={CustomerDetails?.taluk}
                placeholder="Taluk"
                id="taluk"
                name="taluk"
                error={CustomerDetails?.taluk == "" ? props.isValid : false}
                style={{ opacity: "0.6" }}
                readOnly={true}
                maxLength="150"
              />

              <FormInput
                type="text"
                fluid={true}
                label="City"
                value={CustomerDetails?.city}
                placeholder="City"
                id="city"
                name="city"
                error={CustomerDetails?.city == "" ? props.isValid : false}
                style={{ opacity: "0.6" }}
                readOnly={true}
                maxLength="150"
              />

              <FormInput
                type="text"
                fluid={true}
                label="State"
                value={CustomerDetails?.state}
                placeholder="State"
                id="state"
                name="state"
                error={CustomerDetails?.state == "" ? props.isValid : false}
                style={{ opacity: "0.6" }}
                readOnly={true}
                maxLength="150"
              />
            </FormGroup>
            <Label as="a" color="red" ribbon>
              Scheme Info
            </Label>
            <FormGroup widths="equal">
              <FormInput
                type="text"
                fluid={true}
                label="Scheme"
                value={CustomerDetails?.schemename}
                id="schemename"
                name="schemename"
                style={{ opacity: "0.6" }}
                readOnly={true}
              />

              <FormInput
                type="number"
                fluid={true}
                label="Scheme Amount"
                value={CustomerDetails?.schemeamount}
                placeholder="Scheme Amount"
                id="schemeamount"
                name="schemeamount"
                maxLength="50"
                style={{ opacity: "0.6" }}
              />

              <FormInput
                type="number"
                fluid={true}
                label="Weight"
                value={installmentWeight}
                placeholder="Weight"
                id="schemeweight"
                name="schemeweight"
                maxLength="50"
                style={{ opacity: "0.6" }}
              />

              <FormInput
                type="number"
                fluid={true}
                label="Amount Paid"
                value={totalSchemeAmount}
                placeholder="Scheme Amount"
                id="schemeamount"
                name="schemeamount"
                maxLength="50"
                style={{ opacity: "0.6" }}
              />

              {/* <FormInput
                type="number"
                fluid={true}
                label="Group Serial Number"
                value={
                  CustomerDetails?.availableschemes?.[0]?.groupnumber || ""
                }
                placeholder="Serial Number"
                id="groupserialnumber"
                name="groupserialnumber"
                maxLength="150"
                style={{ opacity: "0.6" }}
              /> */}

              <FormInput
                type="text"
                fluid={true}
                label="Group Name"
                value={CustomerDetails?.availableschemes?.[0]?.groupname || ""}
                placeholder="Group Name"
                id="groupname"
                name="groupname"
                maxLength="50"
                style={{ opacity: "0.6" }}
              />
            </FormGroup>
            <Label as="a" color="red" ribbon>
              Nominee Info
            </Label>
            <FormGroup widths={"equal"}>
              <FormInput
                type="text"
                fluid={true}
                label="Name"
                value={CustomerDetails?.nomineeName}
                placeholder="Name"
                id="nomineeName"
                name="nomineeName"
                style={{ opacity: "0.6" }}
                readOnly={true}
              />
              <FormInput
                type="text"
                id="nomineeRelation"
                name="nomineeRelation"
                placeholder="Relationship"
                label="Relationship"
                fluid={true}
                maxLength={100}
                value={CustomerDetails?.nomineeRelation}
                style={{ opacity: "0.6" }}
                readOnly={true}
              />
              <FormInput
                type="number"
                fluid={true}
                label="Mobile Number"
                value={CustomerDetails?.nomineeMobile}
                placeholder="Mobile Number"
                id="nomineeMobile"
                name="nomineeMobile"
                style={{ opacity: "0.6" }}
                readOnly={true}
              />
            </FormGroup>
            <Label as="a" color="red" ribbon>
              Photos
            </Label>
            {/* <FormGroup widths="equal"> */}
            <Grid className="csgrid">
              <GridRow columns={2} centered>
                <GridColumn>
                  <Image
                    width="450px"
                    height="400px"
                    src={
                      CustomerDetails?.photo1
                        ? CustomerDetails?.photo1
                        : process.env.REACT_APP_DUMMY_IMAGE
                    }
                  />
                </GridColumn>
                <GridColumn>
                  <Image
                    width="450px"
                    height="400px"
                    src={
                      CustomerDetails?.photo2
                        ? CustomerDetails?.photo2
                        : process.env.REACT_APP_DUMMY_IMAGE
                    }
                  />
                </GridColumn>
              </GridRow>
            </Grid>
            <FormGroup widths="equal" style={{ marginTop: 3 + "%" }}>
              <Button
                icon="plus"
                labelPosition="left"
                color="linkedin"
                fluid={true}
                size="big"
                type="button"
                id="submit"
                name="submit"
                content="Submit"
                onClick={onSubmitApprove}
                disabled={approved}
              ></Button>
            </FormGroup>
            <ToastContainer />
          </Form>
        </Container>
      )}
    </div>
  );
}
export default CustomerSchemeClose;
