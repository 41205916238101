import React, { useState, useEffect, useContext } from "react";
import { ToastContainer } from "react-toastify";
import {
  Form,
  FormSelect,
  FormField,
  Button,
  FormGroup,
  Container,
} from "semantic-ui-react";
import { useGlobalContext } from "../lib/types.ts";
import { Dropdowns, EmployeeConfigMenus } from "../lib/dbobjectmodel.ts";
import { getDropdown } from "../utils/common.tsx";
import { EmployeeMenuConfigInitialState } from "../lib/initialState.ts";
import { routes } from "../routes.js";
import UserRoleTable from "../components/Table/userroletable.jsx";
import { TableHeaders } from "../components/Table/tableheader.js";
import { HttpGet, useFetch } from "../lib/useFetch.tsx";
import Skeleton from "react-loading-skeleton";

function ConfigureMenus(props: any) {
  const {} = props;
  const { sharedData } = useContext(useGlobalContext);
  const [empmenus, setEmpMenus] = useState<EmployeeConfigMenus>(
    EmployeeMenuConfigInitialState
  );
  const [departments, setDepartments] = useState<Dropdowns[]>([]);
  const [menus, setMenus] = useState<EmployeeConfigMenus[]>([]);
  const [department, setDepartment] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // const { status, statusText, data, error, loading } = useFetch<[]>(
  //   process.env.REACT_APP_GET_CONFIG_MENUS,
  //   [],
  //   undefined
  // );

  // useEffect(() => {
  //   data.map((m) => {
  //     m.all = m.all == 0 ? false : true;
  //     m.view = m.view == 0 ? false : true;
  //     m.add = m.add == 0 ? false : true;
  //     m.edit = m.edit == 0 ? false : true;
  //     m.delete = m.delete == 0 ? false : true;
  //   });

  //   setTimeout(() => {
  //     setMenus(data);
  //   }, 1000);
  // }, [status == 200]);

  useEffect(() => {
    setDepartments(getDropdown("department", sharedData));
  }, []);

  const handleChange = (e, actionMeta) => {
    setMenus([]);
    setIsLoading(false);
    setEmpMenus({
      ...empmenus,
      [actionMeta.name]: actionMeta.value,
    });

    const selDepartment = departments.filter(
      (d) => d.key === actionMeta.value
    )[0].text;

    setDepartment(selDepartment);

    HttpGet(process.env.REACT_APP_GET_CONFIG_MENUS, selDepartment).then(
      (res: EmployeeConfigMenus[]) => {
        res.map((m) => {
          m.all = m.all == 0 ? false : true;
          m.view = m.view == 0 ? false : true;
          m.add = m.add == 0 ? false : true;
          m.edit = m.edit == 0 ? false : true;
          m.delete = m.delete == 0 ? false : true;
        });
        setTimeout(() => {
          setMenus(res);
        }, 500);
      }
    );
  };

  const _menusss = [
    {
      department: "concern",
      all: false,
      view: false,
      add: false,
      edit: false,
      delete: false,
    },
  ];

  return (
    <div className="content">
      <Container className="project-form">
        <Form>
          <FormField>
            <FormSelect
              required
              fluid
              type="select"
              label="Select Department"
              options={departments}
              id="department"
              name="department"
              error={empmenus?.department == "" ? props.isValid : false}
              multiple={false}
              onChange={handleChange}
              placeholder="Select department"
              value={empmenus?.department}
            />
          </FormField>

          {menus.length > 0 ? (
            <UserRoleTable
              headers={TableHeaders.departments}
              allMenus={menus || []}
              selectedDepartment={department}
            />
          ) : (
            <Skeleton count={isLoading ? 0 : 5} />
          )}
        </Form>
        <ToastContainer />
      </Container>
    </div>
  );
}

export default ConfigureMenus;
