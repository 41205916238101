import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormInput,
  Form,
  Button,
  FormField,
  Checkbox,
  Icon,
  FormSelect,
} from "semantic-ui-react";
import loginImage from "../assets/img/4937.jpg";
import logo_text from "../assets/img/tcslogo.png";
import logo from "../assets/img/logo.png";
import Customspinner from "../components/Customspinner.tsx";
import { ToastContainer } from "react-toastify";
import { ErrorToast, SuccessToast } from "../utils/ProjectToast";
import { Result, useGlobalContext } from "../lib/types.ts";
// import { ConnectDB } from "utils/auditdb";
import { Utility } from "../utils/Utility";
import {
  HttpLoginTrigger,
  HttpSKTMTrigger,
  HttpTrigger,
  useFetch,
} from "../lib/useFetch.tsx";
import { EmployeDetails, GoldRates } from "../lib/dbobjectmodel.ts";
import SecureStorage from "../utils/storageservice.tsx";

function Chits() {
  const navigate = useNavigate();
  const secureStorage = new SecureStorage(sessionStorage);

  const { setEmployeeInfo, setMenuAccess, setGoldRate } =
    useContext(useGlobalContext);
  type LoginInfo = {
    empid: any;
    password: string;
  };

  const InitialState: LoginInfo = {
    empid: "",
    password: "",
  };

  const [loginInfo, setLoginInfo] = useState<LoginInfo>(InitialState);
  const [isValid, setIsValid] = useState(false);
  const [passwordShow, setPasswordShow] = useState("eye slash outline");
  const [spinner, setspinner] = useState(false);

  const onLoginSubmit = () => {
    setspinner(true);
    let _isValid = false;
    Object.keys(loginInfo).map((v) => {
      if (loginInfo[v] === "") {
        _isValid = true;
        return;
      }
    });

    setIsValid(_isValid);

    if (!_isValid) {
      setspinner(true);
      if (loginInfo.empid === "superadmin" || loginInfo.empid === "admin") {
        const _emp: EmployeDetails = {
          ecno: "admin",
          employename: "admin",
          department: "none",
          division: "none",
          designation: "admin",
          yearcode: "none",
          branchname: "none",
          companyname: "sktm",
          branchid: 0,
        };
        const _superadmin: EmployeDetails = {
          ecno: "superadmin",
          employename: "superadmin",
          department: "none",
          division: "none",
          designation: "superadmin",
          yearcode: "none",
          branchname: "none",
          companyname: "sktm",
          branchid: 0,
        };

        if (
          loginInfo.empid === "superadmin" &&
          loginInfo.password === Utility.superadmin.password
        ) {
          setMenuAccess(Utility.superadmin.access);
          SuccessToast("Logged in successfully!");
          setTimeout(() => {
            setEmployeeInfo(_superadmin);
            setspinner(false);
            navigate("/admin/concerns");
          }, 1000);
        } else if (
          loginInfo.empid === "admin" &&
          loginInfo.password === Utility.admin.password
        ) {
          setMenuAccess(Utility.admin.access);
          SuccessToast("Logged in successfully!");
          setTimeout(() => {
            setEmployeeInfo(_emp);
            setspinner(false);
            navigate("/admin/configmenus");
          }, 1000);
        } else {
          ErrorToast("Please check employee id or passord!");
          setspinner(false);
          return;
        }
      } else {
        HttpLoginTrigger(
          String(process.env.REACT_APP_VALIDATE_LOGIN),
          "POST",
          loginInfo
        )
          .then((res: any) => {
            const _res = res?.data[0];
            if (_res?.length > 0) {
              setEmployeeInfo(_res[0]);
              secureStorage.setItem("empinfo", JSON.stringify(_res[0]));
              getGoldRate();

              SuccessToast("Logged in successfully!");
              setTimeout(() => {
                setspinner(false);
                navigate("/admin/dashboard");
              }, 1000);
            } else {
              ErrorToast("Please check employee id or passord!");
              setspinner(false);
            }
          })
          .catch((error) => {
            console.log(error);

            ErrorToast("Please run node server!");
            setspinner(false);
          });
      }
    } else {
      ErrorToast("Please fill employee id and password!");
      setspinner(false);
    }
  };

  const handleChange = (e) => {
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
  };
  const getGoldRate = () => {
    HttpSKTMTrigger(`${process.env.REACT_APP_SKTM_GOLDRATE}`, null)
      .then((res: any) => {
        setTimeout(() => {
          let _goldRate: GoldRates = {
            goldRate: Number(res[0].goldRate),
            silverRate: Number(res[0].silverRate),
          };
          setGoldRate(_goldRate);
          secureStorage.setItem("goldrate", JSON.stringify(_goldRate));
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="react_container">
        <div className="img_container">
          <img src={loginImage} width="100%" height="100%" />
        </div>
        <div className="form_container">
          <div className="react_form  login-box">
            <div className="login_logo">
              <img src={logo_text} width="80%" height="80%" />
            </div>
            <div style={{ marginTop: 3 + "%" }} className="textpara">
              <h3>Please sign-in to your account</h3>
            </div>
            <div style={{ width: 50 + "%", marginTop: 2 + "%" }}>
              <Form>
                <FormField>
                  <FormInput
                    type="text"
                    error={isValid}
                    value={loginInfo.empid}
                    fluid
                    label="Employee Id (ECNO)"
                    id="empid"
                    name="empid"
                    placeholder="Enter Employee Id"
                    onChange={handleChange}
                  />
                </FormField>
                <FormField>
                  <FormInput
                    fluid
                    label="Password"
                    value={loginInfo.password}
                    type={passwordShow === "eye" ? "text" : "password"}
                    error={isValid}
                    placeholder="Enter Password"
                    id="password"
                    name="password"
                    onChange={handleChange}
                    icon={
                      <Icon
                        name={passwordShow}
                        link={true}
                        color="blue"
                        onClick={() => {
                          setPasswordShow(
                            passwordShow === "eye" ? "eye slash outline" : "eye"
                          );
                        }}
                      />
                    }
                  />
                </FormField>
                {/* <FormField>
                  <FormSelect
                    options={groups}
                    value={loginInfo.group}
                    error={isValid}
                    id="gorup"
                    name="group"
                    onChange={handleChange}
                    fluid
                    label="Select Group"
                    placeholder="Select Group"
                  />
                </FormField>
                <FormField>
                  <FormSelect
                    options={branchs}
                    value={loginInfo?.branch}
                    error={isValid}
                    id="branch"
                    name="branch"
                    onChange={handleChange}
                    fluid
                    label="Select Branch"
                    placeholder="Select Branch"
                  />
                </FormField> */}
                <div style={{ marginTop: 10 + "%", marginBottom: 5 + "%" }}>
                  <Button
                    icon="right arrow"
                    labelPosition="right"
                    color="linkedin"
                    fluid
                    size="big"
                    type="submit"
                    id="submit"
                    name="submit"
                    content="Login"
                    onClick={onLoginSubmit}
                  ></Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {spinner ? <Customspinner text="Validating..." /> : ""}
      <ToastContainer />
    </>
  );
}

export default Chits;
